.sendCodeOfEmail {
  width: 5.208333rem;
  display: block;
  border-radius: 0.03125rem;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--theme-dialog-backgound);
  z-index: 9999999;
  padding: 0 0.333333rem 0.369792rem;
}

.sendCodeOfEmail_title {
  height: 0.192708rem;
  font-size: 0.15625rem;
  font-family: "GothamGRBook";
  font-weight: 500;
  text-align: center;
  color: var(--theme-color);
  margin-bottom: 0.171875rem;
  padding-top: 0.270833rem;
}

.email_div {
  display: flex;
  align-items: center;
}

.email_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0.34375rem;
}

.hasSend {
  width: 100%;
  line-height: 0.1875rem;
  font-size: 0.125rem;
  text-align: center;
  margin-top: 0.208333rem;
  position: absolute;
  left: 0;
  bottom: 0.104167rem;
}

.pin_content_phone {
  display: flex;
  align-items: center;
  width: 100%;
}

.sendEmailCode {
  display: flex;
  align-items: center;
  margin-bottom: 0.208333rem;
  position: relative;
  margin-top: 0.104167rem;
}

.codeHint {
  width: 100%;
  line-height: 0.192708rem;
  font-size: 0.15625rem;
  font-family: "GothamGRBook";
  font-weight: 400;
  text-align: left;
  color: var(--dialog-content-color);
  margin: 0.177083rem 0 0.125rem;
}

.sendCodeOfEmail .emailNumber,
.sendCodeOfEmail .emailTile {
  height: 0.208333rem;
  line-height: 0.208333rem;
  font-size: 0.166667rem;
  font-family: "GothamGRBook";
  font-weight: 400;
  text-align: left;
  color: var(--dialog-content-color);
  margin-right: 0.052083rem;
}

.sendEmailCode .sendEmailCode_btn {
  width: 0.989583rem;
  height: 0.416667rem;
  background: rgba(79,80,81,0.5);
  border-radius: 0.020833rem;
  font-size: 0.1875rem;
  text-align: center;
  font-family: "GothamGRBook";
  color: var(--dialog-content-color);
  margin-right: 0.104167rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sendEmailCode_btn.focused {
  background: var(--theme-btn-highlight);
}

.sendEmailCode_btn_text {
  font-size: 0.15625rem;
  display: block;
  width: 100%;
  line-height: 0.223958rem;
}

.sendEmailCode input {
  width: 1.979167rem;
  height: 0.416667rem;
  line-height: 0.416667rem;
  background: rgba(79,80,81,0.5);
  border-radius: 0.020833rem;
  box-sizing: border-box;
  text-indent: 0.0625rem;
  display: block;
  color: var(--dialog-content-color);
  font-size: 0.166667rem;
  font-family: "GothamGRBook";
  font-weight: 400;
  border: none;
  text-align: left;
}

.sendEmailCode input[data-focusable-name="getCodeInput"] {
  width: 2.557292rem;
  height: 0.453125rem;
}

.sendEmailCode input.focused {
  background: var(--theme-btn-highlight);
  color: var(--theme-color);
}

.sendEmailCode .codeWarning {
  position: absolute;
  font-size: 0.125rem;
  color: var(--empty-highlight-color);
  bottom: -0.15625rem;
  left: 0.104167rem;
}

.email_pin_div {
  padding-top: 0.3125rem;
}

.email_pin_right {
  width: 1.34375rem;
  height: 1.796875rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-between;
}

.email_pin_right div {
  width: 0.427083rem;
  height: 0.427083rem;
  border-radius: 0.026042rem;
  text-align: center;
  line-height: 0.427083rem;
  font-size: 0.208333rem;
  background: var(--dialog-pin-bg);
  font-family: 'GothamGRBook';
  color: var(--dialog-content-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.email_pin_right div img {
  width: 80%;
  height: 80%;
  display: block;
}