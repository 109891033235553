.mature_Dialog {
  width: 4.166667rem;
  font-size: 0.177083rem;
  border-radius: 0.03125rem;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--theme-dialog-backgound);
  z-index: 9999999;
  padding-bottom: 0.354167rem;
  display: block;
}

.mature_warning {
  width: 100%;
  height: 0.625rem;
  margin-top: 0.260417rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mature_title {
  height: 0.3125rem;
  font-size: 0.1875rem;
  font-family: "GothamGRBook";
  font-weight: 500;
  text-align: center;
  color: var(--theme-color);
  margin-top: 0.078125rem;
  margin-bottom: 0.052083rem;
}

.matureHint {
  width: 100%;
  line-height: 0.223958rem;
  padding: 0 5%;
  font-size: 0.135417rem;
  font-family: "GothamGRBook";
  font-weight: 400;
  text-align: center;
  color: #CCCCCC;
  margin-bottom: 0.3125rem;
}

.mature_warning img {
  width: 0.416667rem;
  height: 0.416667rem;
  object-fit: cover;
  display: block;
}

.mature_btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 0.458333rem;
}

.mature_btn_Item {
  width: 2.604167rem;
  height: 0.375rem;
  text-align: center;
  line-height: 0.375rem;
  border-radius: 0.03125rem;
  background-color: var(--dialog-btn-Item-bg);
}

.mature_btn_Item.focused {
  background: var(--theme-btn-highlight);
}