.live_content {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  margin: 0.609375rem 0.46875rem 0.208333rem 0.46875rem;
}

.live_content .root_bg_img {
  position: fixed;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.live_content .live_content_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.live_content .live_content::-webkit-scrollbar {
  display: none;
}

.live_content .live_container {
  position: relative;
  margin-bottom: 0.416667rem;
}

.live_content .live_container::-webkit-scrollbar {
  display: none;
}

.live_content .live_title {
  font-size: 0.28125rem;
  font-family: 'GothamGRBook';
  font-weight: bold;
  line-height: 0.338542rem;
  color: var(--title-color);
}

.live_content .live_intro {
  width: 6.25rem;
  font-size: 0.166667rem;
  font-family: 'GothamGRBook';
  font-weight: 400;
  line-height: 0.208333rem;
  margin-top: 0.15625rem;
  color: var(--intro-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.live_content .countdown {
  height: 0.21875rem;
  margin-top: 0.125rem;
  margin-bottom: 0.104167rem;
  font-size: 0.1875rem;
  color: var(--theme-color);
}

.live_content .live_control {
  margin-top: 0.208333rem;
  display: inline-block;
}

.live_content .buy-btn {
  float: left;
  padding: 0 0.229167rem;
  height: 0.385417rem;
  background-color: var(--details-btn-bg);
  border-radius: 0.03125rem;
  margin-right: 0.15625rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.145833rem;
}

.live_content .live_notice {
  float: left;
  padding: 0 0.229167rem;
  height: 0.385417rem;
  background-color: var(--details-btn-bg);
  border-radius: 0.03125rem;
  margin-right: 0.15625rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.live_content .live_play {
  float: left;
  height: 0.385417rem;
  line-height: 0.385417rem;
  border-radius: 0.03125rem;
  margin-right: 0.15625rem;
  background-color: var(--details-btn-bg);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0.229167rem;
}

.live_content .live_play_icon {
  width: 0.21875rem;
  height: 0.21875rem;
}

.live_content .live_play_text {
  font-size: 0.15625rem;
  font-family: 'GothamGRBook';
  font-weight: 500;
  line-height: 0.1875rem;
  color: var(--theme-color);
  margin-left: 0.067708rem;
}

.live_content .live_collect {
  float: left;
  padding: 0 0.229167rem;
  height: 0.385417rem;
  margin-right: 0.15625rem;
  background-color: var(--details-btn-bg);
  border-radius: 0.03125rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.live_content .live_content_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.live_content .category_title {
  height: 0.223958rem;
  font-size: 0.1875rem;
  font-family: 'GothamGRBook';
  font-weight: normal;
  line-height: 0.223958rem;
  margin-bottom: 0.052083rem;
  font-weight: 500;
}

.live_content .wrapper {
  font-family: 'GothamGRBook';
  font-size: 0.088542rem;
  width: 100%;
  font-weight: 500;
  position: relative;
  height: 100%;
  margin-bottom: 0.052083rem;
}

.live_content .wrapper::-webkit-scrollbar {
  display: none;
}

.live_content .wrapper h1 {
  font-weight: 500;
}

.live_content .footer {
  margin: 0.15625rem 0;
}

.live_content .content {
  width: 100%;
}

.live_content div {
  box-sizing: border-box;
}

.live_content .tag-list {
  display: flex;
  align-items: center;
  margin-top: 0.130208rem;
}

.live_content .age-tag {
  min-width: 0.5625rem;
  padding: 0.03125rem 0.104167rem;
  border-radius: 0.020833rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.125rem;
  border: 0.005208rem solid rgba(255,255,255,0.8);
  color: var(--theme-color);
}

.live_content .column {
  padding-top: 0.208333rem;
}