.category_contenter {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  background: var(--theme-content-bg);
}

.category__box_left {
  width: 2.604167rem;
  height: 100vh;
}

.left_list {
  width: 2.604167rem;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  font-size: 0.15625rem;
  color: var(--theme-color);
  font-family: 'GothamGRBook';
  padding: 0.520833rem 0 0.260417rem;
  box-sizing: border-box;
  font-weight: bold;
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
}

.left_list .left-list_wrapper {
  width: 2.604167rem;
  height: 100vh;
  position: relative;
}

.category_item_left {
  width: 2.604167rem;
  height: 0.3125rem;
  margin: 0.0625rem 0;
  padding-left: 0.416667rem;
  box-sizing: border-box;
}

.category_item_left.focused {
  background: var(--categoryItem-focus-bg);
}

.tab_child_item_name {
  width: 2.1875rem;
  height: 0.3125rem;
  line-height: 0.3125rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.right_list {
  min-height: 100vh;
  width: calc(100vw - 2.916667rem);
}

.hide-tab_bar .right_list {
  width: 100%;
}

.right_list_title {
  height: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.46875rem;
}

.right_list_title span {
  line-height: 0.625rem;
  font-size: 0.15625rem;
}

.right_list_title .lineV {
  height: 0.177083rem;
  width: 0.005208rem;
  background-color: #fff;
  display: block;
  margin: 0 0.052083rem;
}

.right_list_title .category__name_current {
  font-size: 0.208333rem;
}

.right_content_wrap {
  width: calc(100vw - 2.8125rem);
  padding-bottom: 0.208333rem;
  box-sizing: border-box;
}

.hide-tab_bar .right_content_wrap {
  width: 100%;
}

.hide-tab_bar .right_content_box {
  width: 100%;
}

.right_content_box {
  width: calc(100vw - 2.8125rem);
  height: 100%;
  position: relative;
  left: 0;
  padding: 0 0.1875rem;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
}

.right_content_item {
  box-sizing: border-box;
  padding-bottom: 0.208333rem;
}