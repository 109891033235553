.live-event_main {
  width: calc(100% - 1.46875rem);
  padding: 0 1.010417rem;
}

.live-event_main .empty {
  display: flex;
  justify-content: center;
}

.live-event_main .empty .empty-wrapper {
  width: 2.333333rem;
  height: 2.333333rem;
}

.live-event_main .empty .empty-wrapper img {
  width: 100%;
}

.live-event_main .selector {
  display: flex;
}

.live-event_main .selector .day-selector {
  width: 4.947917rem;
  margin-right: 0.104167rem;
  height: 0.625rem;
  background: var(--theme-border-color);
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;
}

.live-event_main .selector .day-selector .prev-day,
.live-event_main .selector .day-selector .next-day {
  width: 0.46875rem;
  border-radius: 0.052083rem;
  position: relative;
  background: var(--theme-border-color);
  z-index: 1;
}

.live-event_main .selector .day-selector .day-list {
  flex: 1 1;
  display: flex;
  width: 3.697917rem;
  position: relative;
}

.live-event_main .selector .day-selector .day-list .day-item {
  width: 0.5625rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 0.166667rem;
}

.live-event_main .selector .day-selector .day-list .day-item .date {
  font-size: 0.125rem;
}

.live-event_main .selector .day-selector .day-list .current {
  color: var(--theme-btn-highlight-color);
}

.live-event_main .selector .day-selector .swiper-button-prev,
.live-event_main .selector .day-selector .swiper-button-next,
.live-event_main .selector .day-selector .swiper-button-right {
  width: 0.130208rem;
  height: 0.145833rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.live-event_main .selector .category-selector {
  width: 2.927083rem;
  height: 0.625rem;
  background: var(--theme-border-color);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  box-sizing: border-box;
}

.live-event_main .selector .category-selector .category-wrapper {
  flex: 1 1;
  padding-left: 0.15625rem;
  font-size: 0.15625rem;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.live-event_main .selector .category-selector .category-btn {
  width: 0.46875rem;
  height: 0.625rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.live-event_main .selector .category-selector .category-btn .category-btn_img {
  width: 0.15625rem;
  height: 0.15625rem;
  position: relative;
}

.live-event_main .selector .category-selector .category-btn .category-btn_img .swiper-button-next {
  width: 0.15625rem;
  height: 0.15625rem;
  rotate: 90deg;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.live-event_main .category-list {
  margin: 0.166667rem 0;
}

.live-event_main .category-list .category-list_wrapper {
  position: relative;
  display: flex;
}

.live-event_main .category-list .category-list_wrapper .category-list_item {
  padding: 0.03125rem 0.104167rem;
  background: var(--theme-border-color);
  border-radius: 0.145833rem;
  margin-right: 0.104167rem;
}

.live-event_main .live-event_status--title {
  font-size: 0.166667rem;
  margin-bottom: 0.09375rem;
}

.live-event_main .live-event_child--list .live-event-child--wrapper {
  display: flex;
  position: relative;
  margin-bottom: 0.166667rem;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-status {
  position: absolute;
  top: 0.0625rem;
  left: 0.0625rem;
  padding: 0.041667rem 0.083333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.020833rem;
  background: var(--theme-float-box-color);
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-status.living {
  background: var(--icon-highlight-color);
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-status.living .event-status_icon {
  width: 0.083333rem;
  height: 0.083333rem;
  margin-right: 0.020833rem;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-status.living .event-status_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-item {
  margin-right: 0.208333rem;
  width: 2.052083rem;
  border-radius: 0.052083rem;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_poster {
  width: 2.03125rem;
  height: 1.140625rem;
  border-radius: 0.020833rem;
  position: relative;
  background-image: url("../../../../../assets/images/defaultImages/atv_default_274x168.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.052083rem;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.052083rem;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_name--wrapper {
  overflow: hidden;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_name {
  font-size: 0.135417rem;
  line-height: 0.1875rem;
  height: 0.21875rem;
  margin-top: 0.041667rem;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.020833rem;
  white-space: nowrap;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_info {
  font-size: 0.09375rem;
  display: flex;
  color: var(--theme-dialog-color);
  padding-left: 0.020833rem;
  padding-bottom: 0.020833rem;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_info .event-iocn {
  width: 0.125rem;
  height: 0.125rem;
  margin-right: 0.041667rem;
  margin-left: 0.078125rem;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_info .event-iocn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.010417rem;
}

.live-event_main .live-event_child--list .live-event-child--wrapper .event-item[focused] {
  border: 2PX solid var(--theme-color);
  box-shadow: 0 0 0.052083rem rgba(255,255,255,0.5);
  box-shadow: 0.026042rem 0.026042rem 0.052083rem rgba(255,255,255,0.5);
  box-sizing: border-box;
  scale: 1.05;
}