.package-page {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
}

.package-page .user-info {
  margin-top: 0.484375rem;
  padding: 0 0.520833rem;
  background: var(--theme-bg);
}

.package-page .current-user {
  padding: 0.020833rem;
  background: var(--theme-btn-category-bgColor);
  border-radius: 0.145833rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
}

.package-page .current-user .avatar {
  width: 0.255208rem;
  height: 0.255208rem;
  border-radius: 50%;
}

.package-page .current-user .avatar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.package-page .current-user .nick-name {
  font-size: 0.125rem;
  margin-left: 0.041667rem;
  padding-right: 0.125rem;
}

.package-page .tab {
  padding: 0 0.520833rem;
  position: relative;
  margin-top: 0.15625rem;
  display: flex;
}

.package-page .tab .tab-item {
  padding: 0.208333rem 0.354167rem;
  background: var(--theme-btn-category-bgColor);
  display: flex;
  align-items: center;
  justify-content: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 0.1875rem;
  position: relative;
  min-width: 2.75rem;
  margin-right: 3PX;
  border-bottom: 3PX solid var(--theme-bg);
  z-index: 3;
}

.package-page .tab .tab-item .icon {
  width: 0.1875rem;
  height: 0.140625rem;
  margin-right: 0.0625rem;
}

.package-page .tab .tab-item .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.package-page .tab .tab-item .icon .icon-unblur {
  display: block;
}

.package-page .tab .tab-item .icon .icon-focused {
  display: none;
}

.package-page .tab .tab-item[focused] {
  border: 3PX solid #ECD2A6;
  border-bottom: 3PX solid var(--theme-bg);
  color: #ECD2A6;
  background-color: inherit !important;
}

.package-page .tab .tab-item[focused] .icon-unblur {
  display: none;
}

.package-page .tab .tab-item[focused] .icon-focused {
  display: block;
}

.package-page .tab .line {
  background: linear-gradient(270deg, #5a493c 0%, #5a493c);
  width: 100vw;
  height: 3PX;
  position: absolute;
  bottom: 0;
  left: 0;
}

.package-page .package-wrapper {
  display: flex;
  padding: 0 0.520833rem;
  overflow: hidden;
  height: 4.6875rem;
  padding-top: 0.208333rem;
}

.package-page .package-wrapper .wrapper-left {
  width: 4.75rem;
  position: relative;
}

.package-page .package-wrapper .wrapper-left .package-list {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.package-page .package-wrapper .wrapper-left .package-list .package-item {
  background: var(--theme-detail-btn-color);
  padding: 0.182292rem 0.208333rem;
  width: 4.75rem;
  margin-bottom: 0.041667rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.020833rem;
}

.package-page .package-wrapper .wrapper-left .package-list .package-item .name-desc {
  width: 3.072917rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.package-page .package-wrapper .wrapper-left .package-list .package-item .name-desc .name {
  color: var(--theme-color);
  font-size: 0.208333rem;
  margin-bottom: 0.041667rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.package-page .package-wrapper .wrapper-left .package-list .package-item .name-desc .desc {
  font-size: 0.145833rem;
  color: var(--theme-dialog-color);
}

.package-page .package-wrapper .wrapper-left .package-list .package-item .price {
  color: #E1BF8A;
  font-size: 0.177083rem;
}

.package-page .package-wrapper .wrapper-left .package-list .package-item[focused] {
  background-color: var(--theme-detail-btn-color) !important;
  border: 2PX solid #E1BF8A;
  scale: 1.05;
}

.package-page .package-wrapper .wrapper-right {
  display: flex;
  align-items: center;
}

.package-page .package-wrapper .wrapper-right .poster {
  width: 2.03125rem;
  height: 1.140625rem;
  border-radius: 0.020833rem;
  position: relative;
  background-image: url("../../../assets/images/defaultImages/atv_default_274x168.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  right: 10%;
  bottom: 20%;
}

.package-page .package-wrapper .wrapper-right .poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.020833rem;
}