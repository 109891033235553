.carousel-wrapper .swiper-container {
  width: 100%;
  margin: 0.182292rem 0;
  position: relative;
  height: 2.708333rem;
}

.carousel-wrapper .swiper-container .swiper-wrapper {
  height: 100%;
}

.carousel-wrapper .swiper-container .swiper-image {
  padding: 0 0.052083rem;
  width: calc(100% - 0.104167rem);
  height: 100%;
}

.carousel-wrapper .swiper-container .swiper-image a {
  display: block;
  width: 100%;
  height: 100%;
}

.carousel-wrapper .swiper-container .swiper-image img {
  width: 100%;
  height: 100%;
  border-radius: 0.041667rem;
  background-image: url("../../../../../assets/images/defaultImages/atv_default_564x316.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.carousel-wrapper .swiper-container .swiper-slide {
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  height: 2.708333rem;
}

.carousel-wrapper .swiper-container .swiper-slide[focused] {
  background: transparent !important;
}

.carousel-wrapper .swiper-container .swiper-slide[focused] img {
  border: 2PX solid var(--theme-color);
  box-shadow: 0.026042rem 0.026042rem 0.052083rem rgba(255,255,255,0.5);
}

.carousel-wrapper .swiper-container .swiper-slide-active {
  -webkit-transform: scale(0.95);
  transform: scale(0.95);
}

.carousel-wrapper .swiper-container_single {
  height: 2.921875rem;
}

.carousel-wrapper .swiper-container_single .swiper-slide {
  height: 2.921875rem;
  width: 5.1875rem;
}

.carousel-wrapper .swiper-container_single .swiper-wrapper {
  display: flex;
  justify-content: center;
}

.carousel-wrapper .swiper-container_two {
  height: 2.921875rem;
}

.carousel-wrapper .swiper-container_two .swiper-wrapper {
  display: flex;
  justify-content: center;
}

.carousel-wrapper .swiper-container_two .swiper-slide {
  height: 2.921875rem;
  width: 5.1875rem;
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
}