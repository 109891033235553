.me-wrapper .not-login_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.625rem;
  font-size: 0.166667rem;
}

.me-wrapper .not-login_wrapper .tips {
  margin-bottom: 0.166667rem;
}

.me-wrapper .not-login_wrapper .login-btn {
  width: 3.75rem;
  height: 0.416667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-btn-category-bgColor);
  border-radius: 0.041667rem;
}

.me-wrapper .login-wrapper {
  margin-top: 0.625rem;
  padding: 0 0.5rem;
  padding-right: 0;
}

.me-wrapper .login-wrapper .user-info_wrapper {
  display: flex;
  align-items: center;
}

.me-wrapper .login-wrapper .user-info_wrapper .user-avater {
  width: 0.9375rem;
  height: 0.9375rem;
  margin-right: 0.09375rem;
}

.me-wrapper .login-wrapper .user-info_wrapper .user-avater img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.me-wrapper .login-wrapper .user-info_wrapper .user-nickname_email .nickname {
  margin-bottom: 0.083333rem;
  font-size: 0.1875rem;
}

.me-wrapper .login-wrapper .user-info_wrapper .user-nickname_email .email {
  font-size: 0.145833rem;
  display: flex;
  align-items: center;
}

.me-wrapper .login-wrapper .user-info_wrapper .user-nickname_email .email .icon {
  margin-right: 0.0625rem;
}

.me-wrapper .login-wrapper .me-menu_wrapper {
  position: relative;
  width: 100%;
  height: 1.25rem;
  margin-top: 0.375rem;
}

.me-wrapper .login-wrapper .me-menu_list {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
}

.me-wrapper .login-wrapper .me-menu_list .menu-item {
  width: 1.65625rem;
  height: 0.9375rem;
  border-radius: 0.052083rem;
  margin-right: 0.208333rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 0.15625rem;
  line-height: 0.208333rem;
  padding: 0.130208rem 0.15625rem;
}

.me-wrapper .login-wrapper .me-menu_list .menu-item[focused] {
  background-color: inherit !important;
  border: 2PX solid var(--theme-color);
  box-shadow: 0 0 0.052083rem rgba(255,255,255,0.5);
  box-shadow: 0.026042rem 0.026042rem 0.052083rem rgba(255,255,255,0.5);
  scale: 1.05;
}

.me-wrapper .login-wrapper .purchase-package {
  margin-top: 0.125rem;
  font-size: 0.15625rem;
}

.me-wrapper .login-wrapper .purchase-package .horizontal-style {
  width: 2.03125rem;
  height: 1.140625rem;
  border-radius: 0.020833rem;
  position: relative;
  background-image: url("../../../../../assets/images/defaultImages/atv_default_274x168.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.me-wrapper .login-wrapper .purchase-package .horizontal-style img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.020833rem;
}

.me-wrapper .login-wrapper .purchase-package .horizontal-style .recommend-more_text {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  font-size: 0.166667rem;
  padding-left: 0.208333rem;
  justify-content: center;
}

.me-wrapper .login-wrapper .purchase-package .horizontal-style a {
  color: var(--theme-color);
}

.me-wrapper .login-wrapper .purchase-package .package-list {
  position: relative;
  height: 1.140625rem;
  margin-top: 0.125rem;
  margin-bottom: 0.333333rem;
}

.me-wrapper .login-wrapper .purchase-package .package-list .package-wrapper {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
}

.me-wrapper .login-wrapper .purchase-package .list-item .package-item {
  overflow: hidden;
  margin-right: 0.208333rem;
}

.me-wrapper .login-wrapper .purchase-package .list-item .package-item a {
  display: block;
  width: 100%;
  height: 100%;
  color: var(--theme-color);
}

.me-wrapper .login-wrapper .purchase-package .list-item .package-item[focused] {
  background: none;
  border: 2PX solid var(--theme-color);
  box-shadow: 0 0 0.052083rem rgba(255,255,255,0.5);
  box-shadow: 0.026042rem 0.026042rem 0.052083rem rgba(255,255,255,0.5);
  box-sizing: border-box;
  scale: 1.05;
}

.me-wrapper .login-wrapper .purchase-package .list-item .title-section {
  background: linear-gradient(180deg, rgba(0,0,0,0), #000);
  height: 0.21875rem;
  line-height: 0.21875rem;
  width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 0.09375rem;
  padding: 0 0.041667rem;
}

.me-wrapper .login-wrapper .purchase-package .package-list {
  position: relative;
}

.me-wrapper .login-wrapper .purchase-package .package-list .package-info {
  position: absolute;
  padding: 0.041667rem;
  z-index: 1;
  font-size: 0.125rem;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0,0,0,0.1);
}

.me-wrapper .login-wrapper .purchase-package .package-list .package-info .package-info_wrapper {
  bottom: 0.145833rem;
  left: 0.041667rem;
  position: absolute;
}

.me-wrapper .login-wrapper .purchase-package .package-list .package-info .package-info_wrapper .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0.0625rem;
}

.me-wrapper .login-wrapper .purchase-package .package-list .package-info .package-info_wrapper .price {
  color: var(--leaderboard-third-color);
}