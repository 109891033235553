.all-event_page {
  width: 100vw;
  height: 100vh;
}

.all-event_page .page-title {
  background: var(--theme-bg);
  padding: 0.333333rem 0.494792rem 0.114583rem 0.494792rem;
  font-size: 0.25rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
}

.all-event_page .all-event_main {
  margin-top: 0.9375rem;
  padding: 0 0.5rem;
  padding-bottom: 0.833333rem;
  position: relative;
}

.all-event_page .all-event_main .live-event_list {
  position: relative;
}

.all-event_page .all-event_main .live-event_status--title {
  font-size: 0.166667rem;
  margin-bottom: 0.09375rem;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper {
  display: flex;
  position: relative;
  margin-bottom: 0.166667rem;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-status {
  position: absolute;
  top: 0.0625rem;
  left: 0.0625rem;
  padding: 0.041667rem 0.083333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.020833rem;
  background: var(--theme-float-box-color);
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-status.living {
  background: var(--icon-highlight-color);
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-status.living .event-status_icon {
  width: 0.083333rem;
  height: 0.083333rem;
  margin-right: 0.020833rem;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-status.living .event-status_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-item {
  margin-right: 0.208333rem;
  width: 2.052083rem;
  border-radius: 0.052083rem;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_name--wrapper {
  overflow: hidden;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_poster {
  width: 2.03125rem;
  height: 1.140625rem;
  border-radius: 0.020833rem;
  position: relative;
  background-image: url("../../../assets/images/defaultImages/atv_default_274x168.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0.052083rem;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.052083rem;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_name {
  font-size: 0.135417rem;
  line-height: 0.1875rem;
  height: 0.21875rem;
  margin-top: 0.041667rem;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0.020833rem;
  white-space: nowrap;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_info {
  font-size: 0.09375rem;
  display: flex;
  color: var(--theme-dialog-color);
  padding-left: 0.020833rem;
  padding-bottom: 0.020833rem;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_info .event-iocn {
  width: 0.125rem;
  height: 0.125rem;
  margin-right: 0.041667rem;
  margin-left: 0.078125rem;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-item .event-item_info .event-iocn img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.010417rem;
}

.all-event_page .all-event_main .live-event_child--list .live-event-child--wrapper .event-item[focused] {
  border: 2PX solid var(--theme-color);
  box-shadow: 0 0 0.052083rem rgba(255,255,255,0.5);
  box-shadow: 0.026042rem 0.026042rem 0.052083rem rgba(255,255,255,0.5);
  box-sizing: border-box;
  scale: 1.05;
}