.vod-player {
  width: 100vw;
  height: 100vh;
}

.vod-player .player-main {
  width: 100vw;
  height: 100vh;
}

.vod-player .play-type_tag {
  padding: 0.0625rem;
  background: rgba(0,0,0,0.4);
  border: 2PX solid rgba(255,255,255,0.7);
  border-radius: 0.03125rem;
  position: absolute;
  top: 0.260417rem;
  left: 0.260417rem;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 0.625rem;
}

.vod-player .controls {
  height: 1.5625rem;
  width: 100vw;
  background: rgba(0,0,0,0.3);
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  padding: 0 1.09375rem;
  padding-top: 0.479167rem;
}

.vod-player .controls .video-name {
  font-size: 0.1875rem;
  margin-left: 0.729167rem;
}

.vod-player .controls .time-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.0625rem;
}

.vod-player .controls .time-bar .current-time {
  font-size: 0.15625rem;
  margin-right: 0.125rem;
}

.vod-player .controls .time-bar .total-time {
  font-size: 0.15625rem;
  margin-left: 0.125rem;
}

.vod-player .controls .time-bar .progress-track {
  height: 0.041667rem;
  flex: 1 1;
  background: rgba(255,255,255,0.3);
  position: relative;
}

.vod-player .controls .time-bar .progress-track .progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 0.041667rem;
  width: 0%;
  background: var(--theme-btn-highlight-color);
}

.vod-player .controls .time-bar .progress-track .dot-box {
  border-radius: 50%;
  width: 0.130208rem;
  height: 0.130208rem;
  position: absolute;
  top: -0.052083rem;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
}

.vod-player .controls .time-bar .progress-track .dot-box .dot-highlight {
  width: 0.130208rem;
  height: 0.130208rem;
  border-radius: 50%;
  background: var(--theme-color);
}

.vod-player .controls .setting-group {
  margin: 0 0.729167rem;
  display: flex;
  margin-top: 0.0625rem;
  font-size: 0.145833rem;
}

.vod-player .controls .setting-group .setting-btn,
.vod-player .controls .setting-group .selection-btn {
  padding: 0.041667rem;
  display: flex;
  align-items: center;
  border-radius: 0.020833rem;
}

.vod-player .controls .setting-group .setting-btn .icon,
.vod-player .controls .setting-group .selection-btn .icon {
  display: flex;
  width: 0.15625rem;
  height: 0.15625rem;
  margin-right: 0.083333rem;
}

.vod-player .controls .setting-group .setting-btn .icon img,
.vod-player .controls .setting-group .selection-btn .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vod-player .controls .setting-group .selection-btn {
  display: none;
}

.vod-player .controls .setting-group .playback-rate_btn {
  padding: 0.083333rem 0.0625rem;
  border-radius: 0.020833rem;
  margin-left: 0.625rem;
}

.vod-player .controls .setting-group .selection-btn {
  margin-left: 1.6875rem;
}

.vod-player .controls .setting-group .play-btn_group {
  display: flex;
  align-items: center;
  margin-left: 0.833333rem;
}

.vod-player .controls .setting-group .play-btn_group .forward-btn {
  width: 0.302083rem;
  height: 0.302083rem;
  margin-right: 0.052083rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.vod-player .controls .setting-group .play-btn_group .forward-btn img {
  width: 80%;
  height: 80%;
}

.vod-player .controls .setting-group .play-btn_group .play-pause_btn {
  width: 0.302083rem;
  height: 0.302083rem;
  margin-right: 0.052083rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.vod-player .controls .setting-group .play-btn_group .play-pause_btn img {
  width: 60%;
  height: 60%;
}

.vod-player .controls .setting-group .play-btn_group .rewind {
  width: 0.302083rem;
  height: 0.302083rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.vod-player .controls .setting-group .play-btn_group .rewind img {
  width: 80%;
  height: 80%;
}

.vod-player .controls .setting-group .margin-left_box {
  margin-left: 1.583333rem;
}

.vod-player .pannel {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 30%;
  background: var(--theme-float-box-color);
  padding: 0.416667rem 0;
}

.vod-player .pannel .pannel-title {
  font-size: 0.197917rem;
  padding-left: 0.21875rem;
}

.vod-player .pannel .option {
  margin-top: 0.208333rem;
}

.vod-player .pannel .option .option-item {
  padding: 0.0625rem 0.21875rem;
  height: 0.625rem;
  box-sizing: border-box;
}

.vod-player .pannel .option .option-item .option-label {
  font-size: 0.166667rem;
}

.vod-player .pannel .option .option-item .option-value {
  margin-top: 0.0625rem;
  font-size: 0.125rem;
}

.vod-player .pannel .option .option-list-item {
  padding: 0.0625rem 0.21875rem;
  height: 0.625rem;
  box-sizing: border-box;
  font-size: 0.125rem;
  display: flex;
  align-items: center;
}

.vod-player .pannel .option .option-list-item .icon {
  display: inline-block;
  width: 0.166667rem;
  height: 0.166667rem;
  margin-right: 0.125rem;
}

.vod-player .pannel .option .option-list-item .icon img {
  width: 100%;
  height: 100%;
}

.vod-player .subtitle-wrapper {
  position: absolute;
  bottom: 0.25rem;
  max-width: 4.166667rem;
  width: -webkit-max-content;
  width: max-content;
  text-align: center;
  font-size: 0.166667rem;
  background: rgba(0,0,0,0.1);
  padding: 0.0625rem;
  box-sizing: border-box;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.vod-player .episodes-box {
  height: 1.770833rem;
  width: 100vw;
  background: rgba(0,0,0,0.3);
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  overflow: hidden;
  display: none;
}

.vod-player .episodes-box .episodes-box_title {
  padding-top: 0.083333rem;
  font-size: 0.1875rem;
  margin-left: 0.479167rem;
}

.vod-player .episodes-box .episodes-box_title {
  display: flex;
  align-items: center;
}

.vod-player .episodes-box .episodes-box_title .episodes-icon {
  display: flex;
  width: 0.15625rem;
  height: 0.15625rem;
  margin-right: 0.083333rem;
}

.vod-player .episodes-box .episodes-box_title .episodes-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vod-player .episodes-box .episodes-wrapper {
  padding-left: 0.479167rem;
  margin-top: 0.166667rem;
  position: relative;
  display: flex;
}

.vod-player .episodes-box .episodes-wrapper .column-item_children {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.vod-player .episodes-box .episodes-wrapper .total-time {
  position: absolute;
  right: 0.083333rem;
  top: 0.083333rem;
}

.vod-player .episodes-box .episodes-wrapper .icon {
  position: absolute;
  width: 0.145833rem;
  height: 0.145833rem;
  left: 0.041667rem;
  top: 0.041667rem;
}

.vod-player .episodes-box .episodes-wrapper .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vod-player .dialog {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--theme-bg);
  top: 0;
  left: 0;
  z-index: 12;
  display: none;
}

.vod-player .dialog .dialog-left {
  flex: 3 1;
}

.vod-player .dialog .dialog-left .dialog-left_wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 12%;
  height: 100%;
  justify-content: center;
}

.vod-player .dialog .dialog-left .dialog-left_wrapper .dialog-title {
  font-size: 0.21875rem;
  margin-bottom: 0.166667rem;
}

.vod-player .dialog .dialog-left .dialog-left_wrapper .dialog-desc {
  font-size: 0.145833rem;
  color: var(--theme-dialog-color);
}

.vod-player .dialog .dialog-right {
  flex: 1 1;
  background: var(--theme-float-box-color);
}

.vod-player .dialog .dialog-right .btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.vod-player .dialog .dialog-right .btn-group .btn-group_item {
  padding: 0.083333rem 0.166667rem;
  font-size: 0.166667rem;
}

.vod-player .content-grade_pannel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--theme-bg);
  top: 0;
  left: 0;
  z-index: 12;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper {
  padding: 0 1.354167rem;
  margin-top: 1.354167rem;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper {
  margin-bottom: 0.208333rem;
  display: flex;
  align-items: center;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper .logo {
  height: 0.546875rem;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper .logo img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper .line {
  background: var(--theme-border-color);
  width: 0.020833rem;
  height: 0.3125rem;
  margin-left: 0.34375rem;
  margin-right: 0.364583rem;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper .age-icon {
  width: 0.427083rem;
  height: 0.427083rem;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper .age-icon img {
  max-width: 100%;
  max-height: 100%;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_main {
  height: 1.614583rem;
  overflow-y: hidden;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_main .rating-desc {
  color: var(--theme-color);
  padding-bottom: 0.416667rem;
  font-size: 0.145833rem;
  line-height: 0.1875rem;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_main .warning-desc {
  color: var(--theme-dialog-color);
  line-height: 0.1875rem;
  font-size: 0.114583rem;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer {
  position: absolute;
  bottom: 0.375rem;
  right: 0.4375rem;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer .grade-pannel_skip {
  min-width: 1.177083rem;
  padding: 0 0.0625rem;
  display: none;
  height: 0.416667rem;
  border-radius: 0.052083rem;
  background: var(--theme-btn-bg);
  align-items: center;
  justify-content: center;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer .grade-pannel_skip .skip-icon {
  width: 0.083333rem;
  height: 0.098958rem;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer .grade-pannel_skip .skip-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer .grade-pannel_skip .skip-text {
  font-size: 0.177083rem;
  margin-left: 0.0625rem;
}

.vod-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer .down-time_text {
  font-size: 0.166667rem;
  margin-top: 0.104167rem;
}