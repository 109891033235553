.horizontal-style {
  width: 2.03125rem;
  height: 1.140625rem;
  border-radius: 0.020833rem;
  position: relative;
  background-image: url("../../../assets/images/defaultImages/atv_default_274x168.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.horizontal-style img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.020833rem;
}

.horizontal-style .recommend-more_text {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  font-size: 0.166667rem;
  padding-left: 0.208333rem;
  justify-content: center;
}

.horizontal-style a {
  color: var(--theme-color);
}

.vertical-style {
  width: 1.21875rem;
  height: 1.828125rem;
  border-radius: 0.020833rem;
  position: relative;
  background-image: url("../../../assets/images/defaultImages/atv_default_274x365.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.vertical-style img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.020833rem;
}

.vertical-style .recommend-more_text {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  font-size: 0.166667rem;
  padding-top: 0.208333rem;
  align-items: center;
}

.vertical-style a {
  color: var(--theme-color);
}

.big-vertical {
  width: 2.036458rem;
  height: 3.057292rem;
  border-radius: 0.020833rem;
  position: relative;
  background-image: url("../../../assets/images/defaultImages/atv_default_313x417.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.big-vertical img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.020833rem;
}

.big-vertical .recommend-more_text {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  font-size: 0.166667rem;
  padding-top: 0.416667rem;
  align-items: center;
}

.list-item .recommend-item {
  overflow: hidden;
  margin-right: 0.208333rem;
}

.list-item .recommend-item a {
  display: block;
  width: 100%;
  height: 100%;
  color: var(--theme-color);
}

.list-item .recommend-item[focused] {
  background: none;
  border: 2PX solid var(--theme-color);
  box-shadow: 0 0 0.052083rem rgba(255,255,255,0.5);
  box-shadow: 0.026042rem 0.026042rem 0.052083rem rgba(255,255,255,0.5);
  box-sizing: border-box;
  scale: 1.05;
}

.list-item .title-section {
  background: linear-gradient(180deg, rgba(0,0,0,0), #000);
  height: 0.21875rem;
  line-height: 0.21875rem;
  min-width: 100%;
  position: absolute;
  bottom: 0;
  font-size: 0.09375rem;
  padding: 0 0.041667rem;
}

.list-item .title-section .title-main {
  height: 0.21875rem;
  line-height: 0.21875rem;
  text-wrap: nowrap;
  white-space: nowrap;
}

.mark-icon {
  position: absolute;
  width: auto;
  height: 0.28125rem;
  z-index: 2;
}

.mark-icon img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.mark-icon.right_top {
  top: 0.052083rem;
  right: 0.052083rem;
}

.mark-icon.left_top {
  top: 0.052083rem;
  left: 0.052083rem;
}

.mark-icon.right_bottom {
  bottom: 0.052083rem;
  right: 0.052083rem;
}

.mark-icon.left_bottom {
  bottom: 0.052083rem;
  left: 0.052083rem;
}

.mark-icon.center {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.LazyLoad {
  opacity: 0;
  transition: all 1s ease-in-out;
}

.is-visible {
  opacity: 1;
}