.pin_content {
  width: 4.395833rem;
  height: 2.578125rem;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: var(--theme-dialog-backgound);
  border-radius: 0.041667rem;
}

.hintTextPin {
  position: absolute;
  bottom: 0.104167rem;
  right: 0.260417rem;
  font-size: 0.15625rem;
  font-family: 'GothamGRBook';
  color: var(--dialog-content-color);
  text-align: right;
}

.pin_left {
  width: 2.145833rem;
}

.input_ttitle {
  line-height: 0.223958rem;
  width: 100%;
  margin-bottom: 0.59375rem;
  font-size: 0.1875rem;
  display: block;
  font-family: 'GothamGRBook';
  text-align: center;
  color: var(--dialog-content-color);
}

.input_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15%;
  margin-bottom: 0.416667rem;
}

.input_item {
  height: 0.3125rem;
  line-height: 0.3125rem;
  width: 0.15625rem;
  border: none;
  border-bottom: 0.005208rem solid #757575;
  font-size: 0.25rem;
  text-align: center;
  display: block;
  background: none;
  color: var(--theme-color);
}

.input_item_focus {
  border-bottom: 0.005208rem solid var(--theme-btn-highlight) !important;
}

.pin_right {
  width: 1.34375rem;
  height: 1.796875rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-between;
}

.pin_right div {
  width: 0.427083rem;
  height: 0.427083rem;
  border-radius: 0.026042rem;
  text-align: center;
  line-height: 0.427083rem;
  font-size: 0.208333rem;
  background: var(--dialog-pin-bg);
  font-family: 'GothamGRBook';
  color: var(--dialog-content-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pin_right div.focused {
  background: var(--theme-btn-highlight);
}

.pin_right div img {
  width: 80%;
  height: 80%;
  display: block;
}

.errorTips {
  color: var(--empty-highlight-color);
  font-size: 0.072917rem;
  margin-top: 0.15625rem;
  display: none;
  text-align: center;
}

.init-code_tips {
  color: var(--theme-color) !important;
}

::-webkit-input-placeholder {
  color: var(--dialog-input-placeholder);
}

::-moz-placeholder {
  color: var(--dialog-input-placeholder);
}

:-ms-input-placeholder {
  color: var(--dialog-input-placeholder);
}

:-moz-placeholder {
  color: var(--dialog-input-placeholder);
}