.ad-wrapper {
  width: 8.333333rem;
  margin: 0 auto;
}

.ad-wrapper .ad-item {
  width: 100%;
  height: 1.041667rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0.229167rem;
}

.ad-wrapper .ad-item .ad-img_box {
  width: 8.333333rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../../../../assets/images/defaultImages/atv_default_1728x200.png");
}

.ad-wrapper .ad-item .ad-img_box[focused] {
  background: none;
  border: 2PX solid var(--theme-color);
  box-shadow: 0 0 0.052083rem rgba(255,255,255,0.5);
  box-shadow: 0.026042rem 0.026042rem 0.052083rem rgba(255,255,255,0.5);
  scale: 1.05;
}

.ad-wrapper .recommed-title {
  font-size: 0.166667rem;
  margin-bottom: 0.09375rem;
}