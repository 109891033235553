.column {
  position: relative;
  width: 100%;
  margin-bottom: 0.229167rem;
}

.column .column-main {
  position: relative;
  left: 0;
  display: flex;
  justify-content: flex-start;
}