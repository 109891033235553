.qr-login_page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.qr-login_page .login-left_wrapper {
  width: 1.846354rem;
  height: 3.401042rem;
  margin-right: 0.625rem;
}

.qr-login_page .login-left_wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.qr-login_page .login-right_wrapper .scan-qr_title {
  font-size: 0.229167rem;
  margin-bottom: 0.229167rem;
}

.qr-login_page .login-right_wrapper .scan-qr_step {
  font-size: 0.15625rem;
}

.qr-login_page .login-right_wrapper .scan-qr_step .step-item {
  margin-bottom: 0.125rem;
}

.qr-login_page .login-right_wrapper .qr-code {
  width: 1.25rem;
  height: 1.25rem;
  background: var(--theme-color);
  padding: 0.052083rem;
  margin-top: 0.15625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-login_page .login-right_wrapper .qr-code .qr-code_img {
  width: 1.09375rem !important;
  height: 1.09375rem !important;
}

.qr-login_page .login-right_wrapper .cancel-btn {
  margin-top: 0.125rem;
  width: 2.1875rem;
  height: 0.416667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--theme-btn-category-bgColor);
  border-radius: 0.041667rem;
  font-size: 0.166667rem;
}