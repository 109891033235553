.connect-us_page {
  padding: 0.260417rem 0.5rem;
}

.connect-us_page .page-header {
  font-size: 0.28125rem;
}

.connect-us_page .wrapper {
  display: flex;
  align-items: center;
  margin-top: 0.973958rem;
}

.connect-us_page .wrapper .wrapper-left .wrapper-left-icon {
  width: 4.208333rem;
  height: 2.21875rem;
}

.connect-us_page .wrapper .wrapper-left .wrapper-left-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.connect-us_page .wrapper .wrapper-right {
  margin-left: 0.833333rem;
}

.connect-us_page .wrapper .wrapper-right .contact-info {
  padding-top: 0.104167rem;
}

.connect-us_page .wrapper .wrapper-right .contact-info .info-item {
  margin-bottom: 0.3125rem;
}

.connect-us_page .wrapper .wrapper-right .contact-info .info-item .info-item_title {
  font-size: 0.135417rem;
}

.connect-us_page .wrapper .wrapper-right .contact-info .info-item .info-item_label {
  font-size: 0.166667rem;
  padding-top: 0.041667rem;
}