.search_contenter {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  padding: 0 0 0.260417rem;
  box-sizing: border-box;
}

.search_title_box {
  height: 0.75rem;
  padding: 0.322917rem 0 0 0.494792rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 0.010417rem solid var(--theme-border-color);
}

.search_title_box img {
  width: 0.3125rem;
  height: 0.3125rem;
  display: block;
  margin-right: 0.125rem;
}

.search_title {
  height: 0.302083rem;
  font-size: 0.25rem;
  line-height: 0.270833rem;
  font-weight: 500;
  text-align: left;
}

.searchBox {
  width: 5.197917rem;
  height: 0.40625rem;
  margin: 0.1875rem auto 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background: var(--searchInput-input-bg);
  border-radius: 0.041667rem;
}

.searchBox .searchIcon {
  width: 0.208333rem;
  height: 0.208333rem;
  display: block;
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  left: 0.161458rem;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.emptyText {
  width: 7.072917rem;
  font-size: 0.15625rem;
  color: var(--empty-highlight-color);
  line-height: 0.3125rem;
  margin: 0 auto;
  display: none;
  text-align: center;
}

.searchBox .searchInput {
  width: 100%;
  height: 0.40625rem;
  line-height: 0.40625rem;
  text-indent: 0.484375rem;
  flex: 1 1;
  border: none;
  color: var(--searchInput-input-color);
  font-size: 0.1875rem;
  background: var(--searchInput-input-bg);
}

.searchInput_focus {
  background: var(--theme-btn-highlight) !important;
}

.searchInput::-webkit-input-placeholder {
  color: var(--searchInput-placeholder-color);
}

.searchInput:-ms-input-placeholder {
  color: var(--searchInput-placeholder-color);
}

.searchInput::placeholder {
  color: var(--searchInput-placeholder-color);
}

.result_box {
  width: 100%;
  margin-top: 0.286458rem;
  margin-bottom: 0.416667rem;
  padding-left: 0.677083rem;
  box-sizing: border-box;
}

.searchBox .searchInput.focused {
  background: var(--theme-btn-highlight);
}

.lockIcon {
  width: 0.208333rem;
  height: 0.208333rem;
  display: block;
  position: absolute;
  right: 0.052083rem;
  top: 0.052083rem;
}

.result_box_live {
  width: 100%;
  display: none;
  padding-left: 0.494792rem;
  box-sizing: border-box;
}

.searchResultTitle {
  font-size: 0.1875rem;
  font-family: "GothamGRBook";
  text-align: left;
  color: #8e8e8e;
  height: 0.223958rem;
  font-weight: normal;
  line-height: 0.223958rem;
  margin: 0.104167rem 0 0.208333rem 0;
  font-weight: 500;
}