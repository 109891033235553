.message-detail_page {
  width: 100vw;
  height: 100vh;
  background: var(--theme-bg);
  position: absolute;
  left: 0;
  top: 0;
}

.message-detail_page .page-title {
  border-bottom: 2PX solid var(--theme-border-color);
  padding: 0.333333rem 0.494792rem 0.114583rem 0.494792rem;
  font-size: 0.25rem;
}

.message-detail_page .message-main {
  padding: 0.5rem;
  padding-top: 0.322917rem;
  box-sizing: border-box;
}

.message-detail_page .message-main .message-title {
  display: flex;
  justify-content: center;
  font-size: 0.25rem;
  margin-bottom: 0.229167rem;
}

.message-detail_page .message-main .message-content {
  background: var(--theme-float-box-color);
  min-height: 2.65625rem;
  width: 100%;
  font-size: 0.1875rem;
}

.message-detail_page .message-main .message-content .message-wrapper {
  padding: 0.15625rem 0.125rem;
}

.message-detail_page .message-main .message-content .message-wrapper[focused] {
  background-color: inherit !important;
}