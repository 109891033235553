.vod_content {
  width: 100vw;
  min-height: 100vh;
  position: relative;
  margin: 0.609375rem 0.46875rem 0.208333rem 0.46875rem;
}

.vod_content .root_bg_img {
  position: fixed;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.vod_content .root_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--theme-content-bg);
}

.vod_content .vod_content_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.vod_content .vod_content::-webkit-scrollbar {
  display: none;
}

.vod_content .vod_container {
  position: relative;
  margin-bottom: 0.208333rem;
}

.vod_content .vod_container::-webkit-scrollbar {
  display: none;
}

.vod_content .vod_title {
  font-size: 0.28125rem;
  font-family: 'GothamGRBook';
  font-weight: bold;
  line-height: 0.338542rem;
  color: var(--title-color);
}

.vod_content .vod_frist_info {
  width: 100%;
  height: 0.192708rem;
  display: flex;
  align-items: center;
  margin: 0.104167rem 0;
}

.vod_content .titleInfo {
  display: flex;
  align-items: center;
}

.vod_content .vod_frist_info_item_line {
  height: 0.135417rem;
  width: 0.010417rem;
  background-color: var(--vodFrist-item-color);
  float: left;
  margin: 0 0.125rem;
}

.vod_content .vod_frist_info_item {
  float: left;
  min-width: 0.296875rem;
  height: 0.192708rem;
  font-size: 0.15625rem;
  font-weight: 400;
  line-height: 0.192708rem;
  color: var(--vodFrist-item-color);
  display: flex;
  align-items: center;
  font-family: 'GothamGRBook';
}

.vod_content .category-list {
  display: flex;
  margin-left: 0.125rem;
}

.vod_content .category-list .category-item {
  padding: 0.026042rem 0.057292rem;
  border-radius: 0.020833rem;
  align-items: center;
  justify-content: center;
  font-size: 0.125rem;
  color: var(--categoryItem-color);
  border: 0.005208rem solid var(--categoryItem-color);
  margin-right: 0.125rem;
}

.vod_content .category-item.focused {
  background: var(--theme-btn-highlight);
  border-color: var(--theme-btn-highlight);
}

.vod_content .vod_genre {
  width: 6.25rem;
  font-size: 0.145833rem;
  font-family: 'GothamGRBook';
  font-weight: 400;
  line-height: 0.1875rem;
  color: var(--vodFrist-item-color);
  margin-bottom: 0.078125rem;
  display: flex;
  margin-top: 0.078125rem;
}

.vod_content .vod_genre_title {
  line-height: 0.145833rem;
  height: 0.145833rem;
  flex-shrink: 0;
}

.vod_content .vod_genre_text {
  width: 100%;
  flex: 1 1;
  margin-left: 0.052083rem;
  line-height: 0.145833rem;
}

.vod_content .vod_intro {
  width: 6.25rem;
  font-size: 0.166667rem;
  font-family: 'GothamGRBook';
  font-weight: 400;
  line-height: 0.208333rem;
  color: var(--intro-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.vod_content .vod_intro_fullScreen {
  width: 90% !important;
  -webkit-line-clamp: inherit !important;
}

.vod_content .vod_intro1 {
  width: 4.802083rem;
  font-size: 0.166667rem;
  font-family: 'GothamGRBook';
  font-weight: 400;
  line-height: 0.208333rem;
  color: var(--intro-color);
}

.vod_content .vod_control {
  margin-top: 0.208333rem;
  display: inline-block;
}

.vod_content .vod_play {
  float: left;
  height: 0.385417rem;
  line-height: 0.385417rem;
  border-radius: 0.03125rem;
  background-color: var(--details-btn-bg);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0.229167rem;
}

.vod_content .vod_play_icon {
  width: 0.21875rem;
  height: 0.21875rem;
}

.vod_content .vod_play_text {
  font-size: 0.15625rem;
  font-family: 'GothamGRBook';
  font-weight: 500;
  line-height: 0.1875rem;
  color: var(--title-color);
  margin-left: 0.067708rem;
}

.vod_content .vod_collect {
  float: left;
  padding: 0 0.229167rem;
  height: 0.385417rem;
  margin-left: 0.15625rem;
  background-color: var(--details-btn-bg);
  border-radius: 0.03125rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.vod_content .unfold-desc {
  float: left;
  padding: 0 0.229167rem;
  height: 0.385417rem;
  margin-left: 0.15625rem;
  background-color: var(--details-btn-bg);
  border-radius: 0.03125rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.vod_content .unfold-desc img {
  width: 0.21875rem;
  height: 0.21875rem;
}

.vod_content .unfold-desc.focused {
  background: var(--theme-btn-highlight);
}

.vod_content .vod_play.focused {
  background: var(--theme-btn-highlight);
}

.vod_content .vod_collect.focused {
  background: var(--theme-btn-highlight);
}

.vod_content .vod_content_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.vod_content .category_title {
  height: 0.223958rem;
  font-size: 0.1875rem;
  font-family: 'GothamGRBook';
  font-weight: normal;
  line-height: 0.223958rem;
  margin-bottom: 0.052083rem;
  font-weight: 500;
}

.vod_content .wrapper {
  font-family: 'GothamGRBook';
  font-size: 0.088542rem;
  width: 100%;
  font-weight: 500;
  z-index: 999;
  position: relative;
  height: 100%;
  margin-bottom: 0.052083rem;
}

.vod_content .wrapper::-webkit-scrollbar {
  display: none;
}

.vod_content .wrapper h1 {
  font-weight: 500;
}

.vod_content .footer {
  margin: 0.15625rem 0;
}

.vod_content .content {
  width: 100%;
}

.vod_content div {
  box-sizing: border-box;
}

.vod_content .tag-list {
  display: flex;
  align-items: center;
  margin-top: 0.130208rem;
}

.vod_content .type-age_tag--list {
  display: flex;
  align-items: center;
  margin: 0.208333rem 0;
}

.vod_content .type-tag {
  width: 0.833333rem;
  padding: 0.03125rem 0;
  border-radius: 0.020833rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.125rem;
  background: rgba(255,255,255,0.8);
  border: 0.005208rem solid rgba(255,255,255,0.8);
  color: #333;
}

.vod_content .type-tag span {
  color: #333 !important;
}

.vod_content .age-tag {
  width: 0.833333rem;
  padding: 0.03125rem 0;
  border-radius: 0.020833rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.125rem;
  color: rgba(255,255,255,0.8);
  border: 0.005208rem solid rgba(255,255,255,0.8);
  margin-left: 0.125rem;
}

.vod_content .age-tag_list {
  margin-right: 0.09375rem;
}

.vod_content .column {
  padding-top: 0.208333rem;
}

.photoFull {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  background-color: rgba(0,0,0,0.8) !important;
}

.photoBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.photoFull img {
  max-height: 100vh;
  width: auto;
  max-width: 100vw;
}