.package-detail_page {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 0.5rem;
}

.package-detail_page .package-info {
  display: flex;
  align-items: center;
}

.package-detail_page .package-info .poster {
  width: 2.03125rem;
  height: 1.140625rem;
  border-radius: 0.020833rem;
  position: relative;
  background-image: url("../../../assets/images/defaultImages/atv_default_274x168.png");
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 0.625rem;
}

.package-detail_page .package-info .poster img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.020833rem;
}

.package-detail_page .package-info .product-name {
  font-size: 0.21875rem;
  margin-bottom: 0.125rem;
}

.package-detail_page .package-info .price {
  font-size: 0.1875rem;
  margin-bottom: 0.208333rem;
}

.package-detail_page .package-info .available-payment {
  font-size: 0.145833rem;
}

.package-detail_page .package-info .buy-btn {
  color: #ECD2A6;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.0625rem;
  width: 1.770833rem;
  border-radius: 0.234375rem;
  border: 2PX solid #ECD2A6;
  font-size: 0.166667rem;
  margin-top: 0.125rem;
}

.package-detail_page .package-info .buy-btn[focused] {
  color: var(--theme-bg);
  background: linear-gradient(180deg, #e2c18c, #efdbb4);
}

.package-detail_page .product-content {
  margin-top: 0.333333rem;
}

.package-detail_page .product-content .title {
  font-size: 0.177083rem;
  margin-bottom: 0.125rem;
}

.package-detail_page .product-content .content-list {
  position: relative;
}

.package-detail_page .product-content .content-list .content-list_wrapper {
  position: absolute;
  display: flex;
  left: 0;
}