.favorite_contenter_page {
  width: 100%;
  padding: 0.307292rem 0 0.104167rem;
  color: var(--theme-color);
  background: var(--theme-content-bg);
  font-family: 'GothamGRBook';
}

.favorite_title_Box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.1875rem;
  border-bottom: 0.010417rem solid var(--theme-border-color);
  height: 0.442708rem;
  padding: 0 0.5rem;
}

.favorite_title_box_left {
  display: flex;
  align-items: center;
}

.favorite_title_box_left img {
  height: 0.3125rem;
  width: 0.3125rem;
  display: block;
  margin-right: 0.125rem;
}

.f_title {
  font-size: 0.25rem;
  font-weight: normal;
  line-height: 0.302083rem;
  font-family: 'GothamGRBook';
  font-weight: 500;
  text-align: left;
  color: var(--theme-color);
}

.del_box {
  display: flex;
  align-items: center;
  margin-right: 0.0625rem;
}

.del_text {
  width: 1.036458rem;
  height: 0.34375rem;
  margin-left: 0.166667rem;
  font-size: 0.15625rem;
  line-height: 0.34375rem;
  background: var(--del-bg);
  border-radius: 0.020833rem;
  font-family: 'GothamGRBook';
  font-weight: 500;
  text-align: center;
  color: var(--theme-color);
}

.del_text.focused {
  background: var(--theme-btn-highlight);
}

.del_icon__box.focused {
  background: var(--theme-btn-highlight);
  border-radius: 0.020833rem;
}

.del_icon__box {
  display: flex;
  align-items: center;
  margin-right: 0.0625rem;
  padding: 0 0.15625rem;
}

.del_icon__box .del_icon {
  width: 0.229167rem;
  height: 0.229167rem;
  display: block;
  margin-right: 0.125rem;
}

.del_icon_text {
  height: 0.34375rem;
  font-size: 0.15625rem;
  line-height: 0.34375rem;
  color: var(--theme-color);
}

.f_list_wrap {
  width: 100%;
  min-height: calc(100vh - 1.208333rem);
  background: var(--theme-content-bg);
  overflow: hidden;
  padding-top: 0.104167rem;
}

.f_list-container {
  width: calc(100vw - 1rem);
  margin-left: 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.favorite_list_item {
  height: 100%;
  margin-bottom: 0.208333rem;
  position: relative;
}

.img_select_icon {
  position: absolute;
  left: 0.052083rem;
  top: 0.052083rem;
  width: 0.21875rem;
  height: 0.21875rem;
  display: block;
  z-index: 10;
}

.tab-list {
  display: flex;
  padding: 0 0.5rem;
  margin-bottom: 0.302083rem;
}

.tab-list .tab-item {
  border-bottom: 0.020833rem solid var(--theme-content-bg);
  font-size: 0.244792rem;
  margin-right: 0.364583rem;
  padding: 0.010417rem 0.020833rem;
}

.tab-list .tab-item.focused {
  border-bottom: 0.020833rem solid var(--theme-btn-highlight);
}

.tab-list .tab-item.current {
  border-bottom: 0.020833rem solid var(--theme-btn-highlight);
  background-color: transparent !important;
}