.notification-page {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.notification-page .tab {
  width: 2.03125rem;
  background: var(--theme-float-box-color);
  height: 100%;
  box-sizing: border-box;
  padding-top: 0.864583rem;
}

.notification-page .tab .tab-item {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.1875rem;
  box-sizing: border-box;
  padding: 0.104167rem;
  background: var(--theme-btn-category-bgColor);
}

.notification-page .notification-wrapper {
  margin: 0.760417rem 0.489583rem;
  width: calc(100% - 3.010417rem);
  height: 100%;
}

.notification-page .notification-wrapper .message-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.notification-page .notification-wrapper .message-wrapper .message-list {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item {
  width: 100%;
  height: 0.833333rem;
  box-sizing: border-box;
  padding: 0.125rem 0;
  padding-right: 0.166667rem;
  padding-left: 0.375rem;
  margin-bottom: 0.0625rem;
  border-radius: 0.0625rem;
  color: var(--color-9A9A9A);
  background: var(--theme-btn-category-bgColor);
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .un-read {
  position: absolute;
  right: 0.083333rem;
  top: 0.020833rem;
  font-style: italic;
  font-size: 0.125rem;
  color: var(--icon-highlight-color);
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .title-box {
  display: flex;
  justify-content: space-between;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .title-left {
  width: 70%;
  font-size: 0.1875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .title-left .readed-icon {
  width: 0.208333rem;
  height: 0.208333rem;
  margin-right: 0.083333rem;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .title-left .readed-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .title-left .readed-icon .read-focused {
  display: none;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .title-right {
  display: flex;
  align-items: center;
  font-size: 0.166667rem;
  position: relative;
  padding-right: 0.260417rem;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .icon {
  width: 0.15625rem;
  height: 0.15625rem;
  margin-right: 0.0625rem;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .icon .focus-icon {
  display: none;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item .messge-text {
  margin-top: 0.125rem;
  font-size: 0.1875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item[focused] {
  color: var(--theme-color);
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item[focused] .icon .focus-icon {
  display: block;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item[focused] .icon .normal-icon {
  display: none;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item[focused] .read-focused {
  display: block !important;
}

.notification-page .notification-wrapper .message-wrapper .message-list .message-list_item[focused] .read-normal {
  display: none;
}