.startup {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
}

.startup .swiper-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.startup .swiper-container .swiper-wrapper {
  height: 100%;
}

.startup .swiper-container .swiper-image {
  width: 100%;
  height: 100%;
}

.startup .swiper-container .swiper-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.startup .swiper-container .swiper-slide[focused] {
  background: transparent !important;
}

.startup .downtime {
  background: var(--transparent-background-color);
  width: 0.333333rem;
  height: 0.333333rem;
  position: fixed;
  z-index: 100;
  top: 0.166667rem;
  right: 0.166667rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.166667rem;
  color: var(--theme-btn-highlight);
}

.startup .downtime[focused] {
  background: var(--transparent-background-color) !important;
}