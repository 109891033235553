.rich-text .rich-text_wrapper p {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
  background-color: transparent !important;
  background-color: initial !important;
}

.rich-text .rich-text_wrapper span {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll !important;
  background: initial !important;
  background-color: transparent !important;
  background-color: initial !important;
}