.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--transparent-background-color);
  top: 0;
  left: 0;
  z-index: 12;
  display: none;
}

.popup .popup-left {
  flex: 3 1;
}

.popup .popup-right {
  flex: 1 1;
  background: var(--theme-float-box-color);
  padding-top: 0.5rem;
}

.popup .popup-right .title {
  font-size: 0.15625rem;
  padding: 0 0.208333rem;
}

.popup .popup-right .title .desc {
  font-size: 0.104167rem;
  margin-top: 0.0625rem;
  color: var(--theme-dialog-color);
}

.popup .popup-right .current-device {
  margin-top: 0.166667rem;
  margin-bottom: 0.125rem;
}

.popup .popup-right .item-left {
  display: flex;
  align-items: center;
}

.popup .popup-right .device-item[focused] {
  background-color: inherit !important;
}

.popup .popup-right .device-item[focused] .item-button {
  background-color: var(--theme-btn-highlight) !important;
}

.popup .popup-right .current-device,
.popup .popup-right .device-item {
  display: flex;
  padding: 0.0625rem 0.208333rem;
  align-items: center;
  justify-content: space-between;
}

.popup .popup-right .current-device .icon,
.popup .popup-right .device-item .icon {
  width: 0.1875rem;
  height: 0.1875rem;
}

.popup .popup-right .current-device .icon img,
.popup .popup-right .device-item .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popup .popup-right .current-device .item-info,
.popup .popup-right .device-item .item-info {
  margin-left: 0.125rem;
}

.popup .popup-right .current-device .item-info .item-name,
.popup .popup-right .device-item .item-info .item-name {
  font-size: 0.104167rem;
  line-height: 0.270833rem;
  width: 0.833333rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup .popup-right .current-device .item-info .item-value,
.popup .popup-right .device-item .item-info .item-value {
  font-size: 0.083333rem;
  color: var(--color-9A9A9A);
}

.popup .popup-right .item-button {
  width: 0.625rem;
  height: 0.208333rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.041667rem;
  background: var(--theme-btn-category-bgColor);
  font-size: 0.104167rem;
}

.popup .popup-right .device-box {
  position: relative;
  overflow: hidden;
  height: 2.916667rem;
}

.popup .popup-right .device-box .device-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}