.full-screen_video {
  padding: 0 0.5rem;
}

.full-screen_video .recommed-title {
  font-size: 0.166667rem;
  margin-bottom: 0.09375rem;
}

.full-screen_video .recommend-content_title {
  font-size: 0.375rem;
  margin-top: 0.520833rem;
  height: 0.625rem;
}

.full-screen_video .recommend-content_desc {
  font-size: 0.15625rem;
  margin-top: 0.208333rem;
  margin-bottom: 0.520833rem;
  height: 0.729167rem;
  line-height: 0.1875rem;
  width: 40%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}