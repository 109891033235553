.setting-popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--transparent-background-color);
  top: 0;
  left: 0;
  z-index: 12;
  display: none;
}

.setting-popup .popup-left {
  flex: 3 1;
}

.setting-popup .popup-right {
  flex: 1 1;
  background: var(--theme-float-box-color);
  padding-top: 0.5rem;
}

.setting-popup .popup-right .title {
  font-size: 0.15625rem;
  padding: 0 0.208333rem;
}

.setting-popup .popup-right .setting-box {
  margin-top: 0.166667rem;
  position: relative;
  height: 4.166667rem;
  overflow: hidden;
}

.setting-popup .popup-right .setting-box .setting-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.setting-popup .popup-right .setting-box .setting-item {
  display: flex;
  padding: 0.0625rem 0.208333rem;
  align-items: center;
}

.setting-popup .popup-right .setting-box .setting-item .icon {
  width: 0.1875rem;
  height: 0.1875rem;
}

.setting-popup .popup-right .setting-box .setting-item .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.setting-popup .popup-right .setting-box .setting-item .item-info {
  margin-left: 0.125rem;
}

.setting-popup .popup-right .setting-box .setting-item .item-info .item-name {
  font-size: 0.125rem;
  line-height: 0.270833rem;
}

.setting-popup .popup-right .setting-box .setting-item .item-info .item-value {
  font-size: 0.09375rem;
  color: var(--color-9A9A9A);
}