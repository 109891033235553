.empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.empty .empty-wrapper {
  width: 2.333333rem;
  height: 2.333333rem;
}

.empty .empty-wrapper img {
  width: 100%;
}

.empty .empty-desc {
  font-size: 0.145833rem;
  margin-top: 0.125rem;
}