.subscription-page {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

.subscription-page .page-header {
  border-bottom: 2PX solid var(--theme-border-color);
  padding: 0 0.5rem;
  padding-top: 0.322917rem;
  padding-bottom: 0.125rem;
  display: flex;
  align-items: center;
}

.subscription-page .page-header .page-icon {
  width: 0.3125rem;
  height: 0.3125rem;
}

.subscription-page .page-header .page-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subscription-page .page-header .page-title {
  font-size: 0.25rem;
  margin-left: 0.125rem;
}

.subscription-page .subscription-wrapper {
  margin: 0 0.5rem;
  padding: 0.229167rem;
  background-color: var(--theme-border-color);
  margin-top: 0.286458rem;
  border-radius: 0.020833rem;
  height: 2.5rem;
  box-sizing: border-box;
  overflow: hidden;
}

.subscription-page .subscription-info_btn {
  display: flex;
  justify-content: space-between;
}

.subscription-page .subscription-info_btn .user-info {
  display: flex;
  align-items: center;
  font-size: 0.1875rem;
}

.subscription-page .subscription-info_btn .user-info .user-avater {
  width: 0.463542rem;
  height: 0.463542rem;
  border-radius: 50%;
  margin-right: 0.15625rem;
}

.subscription-page .subscription-info_btn .user-info .user-avater img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subscription-page .subscription-info_btn .user-info .vip-icon {
  width: 0.182292rem;
  height: 0.161458rem;
  margin-left: 0.083333rem;
}

.subscription-page .subscription-info_btn .user-info .vip-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subscription-page .subscription-info_btn .renew-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.223958rem;
  height: 0.442708rem;
  padding: 0 0.270833rem;
  border: 2PX solid #e2c18c;
  color: #e2c18c;
  font-size: 0.1875rem;
}

.subscription-page .subscription-info_btn .renew-btn[focused] {
  color: var(--theme-bg);
  background: linear-gradient(180deg, #e2c18c, #efdbb4);
}

.subscription-page .package-list {
  height: 1.302083rem;
  position: relative;
  margin-top: 0.3125rem;
}

.subscription-page .package-list .package-wrapper {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
}

.subscription-page .package-list .package-wrapper .package-item {
  width: 3.333333rem;
  height: 1.302083rem;
  padding: 0.1875rem 0.15625rem;
  border-radius: 0.052083rem;
  margin-right: 0.208333rem;
  display: flex;
  box-sizing: border-box;
  background: var(--theme-detail-btn-color);
}

.subscription-page .package-list .package-wrapper .package-item .package-icon {
  width: 0.927083rem;
  height: 0.520833rem;
  margin-right: 0.15625rem;
}

.subscription-page .package-list .package-wrapper .package-item .package-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subscription-page .package-list .package-wrapper .package-item .package-info {
  overflow: hidden;
}

.subscription-page .package-list .package-wrapper .package-item .package-info .package-name {
  color: #E1BF8A;
  font-size: 0.166667rem;
  margin-bottom: 0.041667rem;
  line-height: 0.234375rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.subscription-page .package-list .package-wrapper .package-item .package-info .expire-on {
  font-size: 0.15625rem;
  line-height: 0.1875rem;
  margin-bottom: 0.104167rem;
}

.subscription-page .package-list .package-wrapper .package-item .package-info .unsubscribe-btn {
  padding: 0.052083rem 0.140625rem;
  border: 1PX solid var(--theme-color);
  border-radius: 0.161458rem;
  font-size: 0.125rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.subscription-page .package-list .package-wrapper .package-item[focused] {
  background-color: var(--theme-detail-btn-color) !important;
  border: 2PX solid #E1BF8A;
  scale: 1.05;
}

.subscription-page .package-list .package-wrapper .package-item[focused] .unsubscribe-btn {
  color: var(--theme-bg);
  background: linear-gradient(180deg, #e2c18c, #efdbb4);
  border-color: #E1BF8A;
}