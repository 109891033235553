.category-selector_dialog {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--theme-bg);
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-selector_dialog .category-selector_wrapper {
  width: 3.645833rem;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-selector_dialog .category-selector_wrapper .category-selector_dialog--title {
  font-size: 0.260417rem;
  text-align: center;
  margin-bottom: 0.166667rem;
}

.category-selector_dialog .category-selector_wrapper .category-selector_list {
  flex: 1 1;
  position: relative;
  width: 100%;
  height: 3.125rem;
  overflow: hidden;
}

.category-selector_dialog .category-selector_wrapper .category-selector_list .category-selector_list--wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.category-selector_dialog .category-selector_wrapper .category-selector_list .category-item {
  padding: 0.083333rem;
  font-size: 0.145833rem;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.0625rem;
}

.category-selector_dialog .category-selector_wrapper .category-selector_list .category-item .category-item_name {
  flex: 1 1;
}

.category-selector_dialog .category-selector_wrapper .category-selector_list .category-item .category-checkbox {
  width: 0.1875rem;
  height: 0.1875rem;
}

.category-selector_dialog .category-selector_wrapper .category-selector_confirm {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.375rem;
  font-size: 0.15625rem;
  border-radius: 0.052083rem;
  background: var(--theme-btn-category-bgColor);
  width: 1.666667rem;
  margin-top: 0.166667rem;
}