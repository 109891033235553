.contanierHome {
  width: 100vw;
  padding: 0 0.3125rem 0 0.572917rem;
  box-sizing: border-box;
  background: var(--theme-content-bg);
}

.recommend-position_title {
  font-size: 0.21875rem;
  padding-bottom: 0.0625rem;
  margin-bottom: 0.104167rem;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  padding-top: 0.260417rem;
  width: calc(100vw - 0.520833rem);
}

.recommend-list {
  display: flex;
  flex-wrap: wrap;
}

.recommend-wrap {
  width: calc(100vw - 0.520833rem);
  min-height: 100vh;
}

.recommend-wrap .recommend-list {
  width: 100%;
}

.recommend_content_item {
  box-sizing: border-box;
  padding-bottom: 0.208333rem;
}