.scroll-banner_wrapper {
  width: 9rem;
  margin: 0 auto;
}

.scroll-banner_wrapper .swiper-container {
  margin: 0.182292rem 0;
  position: relative;
  height: 2.708333rem;
  overflow: hidden;
}

.scroll-banner_wrapper .swiper-container .swiper-wrapper {
  height: 100%;
}

.scroll-banner_wrapper .swiper-container .swiper-image {
  width: 100%;
  height: 100%;
  position: relative;
}

.scroll-banner_wrapper .swiper-container .swiper-image a {
  display: block;
  width: 100%;
  height: 100%;
}

.scroll-banner_wrapper .swiper-container .swiper-image img {
  width: 100%;
  height: 100%;
  border-radius: 0.041667rem;
}

.scroll-banner_wrapper .swiper-container .swiper-image .mark-icon {
  position: absolute;
  width: auto;
  height: 0.28125rem;
  z-index: 2;
}

.scroll-banner_wrapper .swiper-container .swiper-image .mark-icon img {
  height: 100%;
  width: auto;
  height: initial;
  object-fit: cover;
}

.scroll-banner_wrapper .swiper-container .swiper-image .mark-icon.right_top {
  top: 0.052083rem;
  right: 0.052083rem;
}

.scroll-banner_wrapper .swiper-container .swiper-image .mark-icon.left_top {
  top: 0.052083rem;
  left: 0.052083rem;
}

.scroll-banner_wrapper .swiper-container .swiper-image .mark-icon.right_bottom {
  bottom: 0.052083rem;
  right: 0.052083rem;
}

.scroll-banner_wrapper .swiper-container .swiper-image .mark-icon.left_bottom {
  bottom: 0.052083rem;
  left: 0.052083rem;
}

.scroll-banner_wrapper .swiper-container .swiper-image .mark-icon.center {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.scroll-banner_wrapper .swiper-container .swiper-slide {
  -webkit-transform: scale(0.99);
  transform: scale(0.99);
  height: 2.625rem;
}

.scroll-banner_wrapper .swiper-container .swiper-slide[focused] {
  background: transparent !important;
}

.scroll-banner_wrapper .swiper-container .swiper-slide[focused] img {
  border: 2PX solid var(--theme-color);
  box-shadow: 0.026042rem 0.026042rem 0.052083rem rgba(255,255,255,0.5);
}

.scroll-banner_wrapper .swiper-container .swiper-slide[focused] .mark-icon img {
  border: 0;
  box-shadow: none;
}