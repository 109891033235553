.tran-content {
  width: 100vw;
  background: var(--theme-content-bg);
}

.tran_contenter {
  width: 100vw;
  min-height: 100vh;
  color: var(--theme-color);
  display: flex;
  margin-top: 0.453125rem;
  position: relative;
}

.tran-header {
  margin-bottom: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: var(--theme-content-bg);
}

.tran-header {
  margin-bottom: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: var(--theme-content-bg);
  display: flex;
  align-items: center;
  border-bottom: 0.010417rem solid #080808;
  background: var(--theme-content-bg);
  height: 0.442708rem;
  padding: 0 0.5rem;
}

.tran-header img {
  width: 0.3125rem;
  height: 0.3125rem;
  display: block;
  margin-right: 0.125rem;
}

.tran-header .tran_title {
  height: 0.302083rem;
  font-size: 0.25rem;
  line-height: 0.270833rem;
  font-family: 'GothamGRBook';
  font-weight: 500;
  text-align: left;
  color: var(--title-color);
}

.tran_menu {
  height: 100%;
  width: 1.822917rem;
  padding-top: 0.3125rem;
  margin-right: 0.302083rem;
  border-right: 0.005208rem solid #0d0f11;
  position: fixed;
  left: 0;
}

.tran_menu_item {
  width: 100%;
  height: 0.427083rem;
  line-height: 0.427083rem;
  background: #2e2f32;
  font-size: 0.1875rem;
  font-family: "GothamGRBook";
  padding-left: 0.3125rem;
  box-sizing: border-box;
  color: var(--title-color);
  position: relative;
  margin-bottom: 0.104167rem;
}

.menu-icon {
  position: absolute;
  width: 0.15625rem;
  height: 0.15625rem;
  right: 0.125rem;
  top: 0.135417rem;
  display: block;
}

.menu-icon img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.tran_title {
  font-size: 0.25rem;
  line-height: 0.307292rem;
  font-family: "GothamGRBook";
  font-weight: 500;
  text-align: center;
  color: var(--title-color);
}

.tran_content {
  width: 7.385417rem;
  margin: 0.15625rem 0 0;
  position: relative;
  margin-left: 2.114583rem;
}

.tran_content_info {
  width: 9rem;
  margin: 0 auto;
}

.emptyText {
  line-height: 0.416667rem;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 0.260417rem;
  color: var(--title-color);
}

.tran_item {
  padding: 0.135417rem 0.166667rem 0 0.375rem;
  width: 7.385417rem;
  height: 0.833333rem;
  background: #1f2022;
  border-radius: 0.041667rem;
  margin-bottom: 0.0625rem;
  color: var(--color-9A9A9A);
  position: relative;
}

.tran_item_title_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.125rem;
}

.isNew {
  width: 0.208333rem;
  height: 0.208333rem;
  position: absolute;
  top: 0.145833rem;
  left: 0.078125rem;
}

.more {
  height: 0.1875rem;
  width: 0.145833rem;
  display: block;
  margin-left: 0.104167rem;
  flex-shrink: 0;
}

.tran_item_title {
  width: 100%;
  flex: 1 1;
  line-height: 0.223958rem;
  font-size: 0.1875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "GothamGRBook";
  font-weight: 500;
}

.tran_item_time {
  width: 1.25rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tran_item_time img {
  width: 0.15625rem;
  height: 0.15625rem;
  display: block;
  margin-right: 0.0625rem;
}

.tran_item_time span {
  line-height: 0.197917rem;
  font-size: 0.166667rem;
  font-family: "GothamGRBook";
  display: block;
}

.tran_item_details {
  width: 100%;
  height: 0.223958rem;
  font-size: 0.1875rem;
  font-family: "GothamGRBook";
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tran_item_current {
  background: var(--theme-btn-highlight);
  color: var(--intro-color) !important;
}

.tran_list_wrap {
  width: 7.385417rem;
  height: 100%;
}

.tran_list_box {
  width: 7.385417rem;
}

.tran_list_box .tran-list_item {
  height: 0.473958rem;
  background: #121212;
  width: 100%;
  border: 0.010417rem solid #121212;
  padding: 0 0.125rem;
  box-sizing: border-box;
  margin-bottom: 0.125rem;
  width: 7.385417rem;
}

.tran_list_box .tran-list_item_focus {
  border: 0.010417rem solid var(--theme-btn-highlight) !important;
  background: #121212 !important;
}

.tran_list_box .tran-list_item.current {
  height: 1.713542rem;
}

.tran_list_box .tran-list_item .tran-list_body {
  display: none;
}

.tran_list_box .tran-list_item.current .tran-list_body {
  display: block;
}

.tran_list_box .tran-list_item .tran-item_header {
  height: 0.473958rem;
  display: flex;
  font-size: 0.1875rem;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
}

.tran_list_box .tran-list_item.current .tran-item_header {
  border-bottom: 0.010417rem solid var(--theme-border-color);
}

.tran-list_item .tran-item_header img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.tran-item_header--left,
.tran-item_header--right {
  display: flex;
  align-items: center;
}

.tran-list_item .tran-item_header .icon-vip {
  width: 0.182292rem;
  height: 0.161458rem;
  margin-right: 0.125rem;
}

.tran-list_item .tran-item_header .icon-arrow {
  width: 0.15625rem;
  height: 0.15625rem;
  margin-left: 0.109375rem;
}

.tran_list_box .tran-list_item .icon-up {
  display: none;
}

.tran_list_box .tran-list_item .icon-down {
  display: block;
}

.tran_list_box .tran-list_item.current .icon-up {
  display: block;
}

.tran_list_box .tran-list_item.current .icon-down {
  display: none;
}

.tran-list_body {
  margin-top: 0.083333rem;
}

.tran-list_body .tran-body_item {
  font-size: 0.15625rem;
  margin-top: 0.041667rem;
  color: var(--color-9A9A9A);
  font-weight: 500;
}

.info_time_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.1875rem;
}

.info_time_box span {
  font-size: 0.125rem;
  font-family: 'GothamGRBook';
  line-height: 0.151042rem;
}

.tran_info_contenter {
  display: block;
}

.tran_info_title_box {
  width: 100%;
  height: 0.75rem;
  border-bottom: 0.010417rem solid var(--theme-border-color);
  padding: 0.333333rem 0 0 0.494792rem;
  margin-bottom: 0.322917rem;
}

.tran_info_title_box .tran_info_title {
  height: 0.302083rem;
  font-size: 0.25rem;
  font-family: "GothamGRBook";
  font-weight: 500;
  text-align: left;
  color: var(--title-color);
}

.info_title {
  font-size: 0.25rem;
  font-family: 'GothamGRBook';
  font-weight: 500;
  line-height: 0.291667rem;
  margin-bottom: 0.229167rem;
  text-align: center;
  height: 0.307292rem;
  color: var(--title-color);
}

.info_content1 {
  font-size: 0.1875rem;
  line-height: 0.260417rem;
  width: 9rem;
  min-height: 3.177083rem;
  background: var(--theme-border-color);
  padding: 0.15625rem 0.125rem;
  font-family: "GothamGRBook";
  font-weight: 500;
  text-align: left;
  color: var(--title-color);
}

.info_wrap {
  overflow: auto;
}

.info_wrap::-webkit-scrollbar {
  display: none;
}