.popup {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--transparent-background-color90) !important;
  top: 0;
  left: 0;
  z-index: 12;
  display: none;
}

.popup .popup-left {
  flex: 3 1;
}

.popup .popup-left .popup-left_wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 12%;
  height: 100%;
  justify-content: center;
}

.popup .popup-left .popup-left_wrapper .popup-title {
  font-size: 0.21875rem;
  margin-bottom: 0.166667rem;
}

.popup .popup-left .popup-left_wrapper .popup-desc {
  font-size: 0.145833rem;
  color: var(--theme-dialog-color);
}

.popup .popup-right {
  flex: 1 1;
  background: var(--theme-float-box-color);
}

.popup .popup-right .btn-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.popup .popup-right .btn-group .btn-group_item {
  padding: 0.083333rem 0.166667rem;
  font-size: 0.166667rem;
}