.sendCodeOfBirthday {
  width: 5.208333rem;
  display: block;
  border-radius: 0.03125rem;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--theme-dialog-backgound);
  z-index: 9999999;
  padding: 0 0.333333rem 0.369792rem;
}

.sendCodeOfBirthday_title {
  height: 0.192708rem;
  font-size: 0.1875rem;
  font-family: "GothamGRBook";
  font-weight: 500;
  text-align: center;
  color: var(--theme-color);
  margin-bottom: 0.171875rem;
  padding-top: 0.270833rem;
}

.forgetPin_div {
  display: flex;
  align-items: center;
}

.forgetPin_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 0.270833rem;
}

.birthdayHint {
  width: 100%;
  line-height: 0.192708rem;
  font-size: 0.135417rem;
  font-family: "GothamGRBook";
  font-weight: 400;
  text-align: center;
  color: #CCCCCC;
  margin: 0.177083rem 0 0.125rem;
}

.sendBirthdayCode {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 0.208333rem;
  position: relative;
  margin-top: 0.104167rem;
}

.input_code {
  width: 0.833333rem;
  height: 0.416667rem;
  line-height: 0.416667rem;
  background: rgba(79,80,81,0.5);
  border-radius: 0.020833rem;
  display: block;
  color: var(--dialog-content-color);
  font-size: 0.166667rem;
  font-family: "GothamGRBook";
  font-weight: 400;
  text-align: center;
  border: 0.005208rem solid var(--theme-color);
}

.input_code_focus {
  background: var(--theme-btn-highlight) !important;
  border: 0.005208rem solid var(--theme-btn-highlight);
}

.input_Y_code {
  width: 1.041667rem;
  height: 0.416667rem;
  font-size: 0.166667rem;
}

.sendBirthdayCode input.focused {
  background: var(--theme-btn-highlight);
  color: var(--theme-color);
}

.birthdayWarning {
  position: absolute;
  font-size: 0.125rem;
  color: var(--empty-highlight-color);
  bottom: -0.15625rem;
  left: 0.104167rem;
}

.birthday_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.birthday_btn_Item {
  width: 1.395833rem;
  height: 0.416667rem;
  font-size: 0.15625rem;
  text-align: center;
  line-height: 0.416667rem;
  border-radius: 0.03125rem;
  background-color: var(--dialog-btn-Item-bg);
}

.birthday_btn_Item.focused {
  background: var(--theme-btn-highlight);
}

.birthday_pin_div {
  padding-top: 0.3125rem;
}

.birthday_pin_right {
  width: 1.34375rem;
  height: 1.796875rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: space-between;
}

.birthday_pin_right div {
  width: 0.427083rem;
  height: 0.427083rem;
  border-radius: 0.026042rem;
  text-align: center;
  line-height: 0.427083rem;
  font-size: 0.208333rem;
  background: var(--dialog-pin-bg);
  font-family: 'GothamGRBook';
  color: var(--dialog-content-color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.birthday_pin_right div img {
  width: 80%;
  height: 80%;
  display: block;
}