.event-player {
  width: 100%;
  height: 100%;
}

.event-player .player-main {
  width: 100vw;
  height: 100vh;
}

.event-player .play-type_tag {
  padding: 0.0625rem;
  background: rgba(0,0,0,0.4);
  border: 2PX solid rgba(255,255,255,0.7);
  border-radius: 0.03125rem;
  position: absolute;
  top: 0.260417rem;
  left: 0.260417rem;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 0.625rem;
}

.event-player .controls {
  height: 1.5625rem;
  width: 100vw;
  background: rgba(0,0,0,0.3);
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  padding: 0 1.822917rem;
  font-size: 0.1875rem;
  display: none;
  justify-content: space-between;
  align-items: center;
}

.event-player .controls .event-time_box {
  display: flex;
  align-items: center;
}

.event-player .controls .event-time_box .event-time_icon {
  width: 0.229167rem;
  height: 0.229167rem;
  margin-right: 0.0625rem;
}

.event-player .controls .event-time_box .event-time_icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-player .content-grade_pannel {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--theme-bg);
  top: 0;
  left: 0;
  z-index: 12;
}

.event-player .content-grade_pannel .grade-pannel_wrapper {
  padding: 0 1.354167rem;
  margin-top: 1.354167rem;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper {
  margin-bottom: 0.208333rem;
  display: flex;
  align-items: center;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper .logo {
  height: 0.546875rem;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper .logo img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper .line {
  background: var(--theme-border-color);
  width: 0.020833rem;
  height: 0.3125rem;
  margin-left: 0.34375rem;
  margin-right: 0.364583rem;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper .age-icon {
  width: 0.427083rem;
  height: 0.427083rem;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .icon-wrapper .age-icon img {
  max-width: 100%;
  max-height: 100%;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_main {
  height: 1.614583rem;
  overflow-y: hidden;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_main .rating-desc {
  color: var(--theme-color);
  padding-bottom: 0.416667rem;
  font-size: 0.145833rem;
  line-height: 0.1875rem;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_main .warning-desc {
  color: var(--theme-dialog-color);
  line-height: 0.1875rem;
  font-size: 0.114583rem;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer {
  position: absolute;
  bottom: 0.375rem;
  right: 0.4375rem;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer .grade-pannel_skip {
  min-width: 1.177083rem;
  padding: 0 0.0625rem;
  display: none;
  height: 0.416667rem;
  border-radius: 0.052083rem;
  background: var(--theme-btn-bg);
  align-items: center;
  justify-content: center;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer .grade-pannel_skip .skip-icon {
  width: 0.083333rem;
  height: 0.098958rem;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer .grade-pannel_skip .skip-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer .grade-pannel_skip .skip-text {
  font-size: 0.177083rem;
  margin-left: 0.0625rem;
}

.event-player .content-grade_pannel .grade-pannel_wrapper .grade-pannel_footer .down-time_text {
  font-size: 0.166667rem;
  margin-top: 0.104167rem;
}