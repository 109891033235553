.voucher-code_page {
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  padding: 0 0 0.260417rem;
}

.voucher-code_page .voucher-title_box {
  height: 0.75rem;
  padding: 0.322917rem 0 0 0.494792rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 2PX solid var(--theme-border-color);
}

.voucher-code_page .voucher-title_box .icon {
  width: 0.3125rem;
  height: 0.3125rem;
  margin-right: 0.125rem;
}

.voucher-code_page .voucher-title_box .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.voucher-code_page .voucher-title_box .voucher-title {
  font-size: 0.25rem;
  line-height: 0.270833rem;
  font-weight: 500;
}

.voucher-code_page .input-box {
  display: flex;
  justify-content: center;
  margin-top: 0.333333rem;
}

.voucher-code_page .input-box input {
  height: 0.40625rem;
  line-height: 0.40625rem;
  text-indent: 0.052083rem;
  width: 5.197917rem;
  background: var(--theme-btn-category-bgColor);
  font-size: 0.1875rem;
  outline: none;
  border: 0;
  color: var(--theme-color);
}

.voucher-code_page .submit-btn {
  height: 0.40625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.666667rem;
  background: var(--theme-btn-category-bgColor);
  margin: 0 auto;
  border-radius: 0.0625rem;
  margin-top: 0.21875rem;
  font-size: 0.1875rem;
}