.dialog {
  background: var(--transparent-background-color);
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
}

.dialog .dialog-body {
  background: var(--theme-dialog-backgound);
  width: 4.166667rem;
  height: 2.291667rem;
  border-radius: 0.041667rem;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 1000;
  padding: 0.442708rem 0.364583rem;
  box-sizing: border-box;
}

.dialog .dialog-body .dialog-title {
  font-size: 0.1875rem;
  font-weight: 600;
  text-align: center;
}

.dialog .dialog-body .dialog-desc {
  margin-top: 0.0625rem;
  line-height: 0.1875rem;
  padding: 0.0625rem;
  font-size: 0.135417rem;
  text-align: center;
  color: var(--theme-dialog-color);
}

.dialog .dialog-body .dialog-btn_group {
  display: flex;
  justify-content: center;
  font-size: 0.15625rem;
  padding: 0 0.338542rem;
  margin-top: 0.182292rem;
}

.dialog .dialog-body .dialog-btn_group .confirm-btn {
  background-color: var(--dialog-btn-Item-bg);
  border-radius: 0.052083rem;
  padding: 0.057292rem 0;
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}