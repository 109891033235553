.series_content {
  min-height: 100vh;
  position: relative;
  margin: 0.609375rem 0.46875rem 0.416667rem 0.46875rem;
}

.series_content .root_bg_img {
  position: fixed;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  min-height: 100vh;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.series_content .series_content_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.series_content .series_content::-webkit-scrollbar {
  display: none;
}

.series_content .series_container {
  position: relative;
  margin-bottom: 0.208333rem;
}

.series_content .series_container::-webkit-scrollbar {
  display: none;
}

.series_content .series_title {
  font-size: 0.28125rem;
  font-family: 'GothamGRBook';
  font-weight: bold;
  line-height: 0.338542rem;
  color: var(--title-color);
}

.series_content .series_intro {
  width: 6.25rem;
  font-size: 0.166667rem;
  font-family: 'GothamGRBook';
  font-weight: 400;
  line-height: 0.208333rem;
  color: var(--intro-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.series_content .series_content_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.series_content .wrapper {
  font-family: 'GothamGRBook';
  font-size: 0.088542rem;
  width: 100%;
  font-weight: 500;
  z-index: 999;
  position: relative;
  height: 100%;
  margin-bottom: 0.052083rem;
}

.series_content .wrapper::-webkit-scrollbar {
  display: none;
}

.series_content .wrapper h1 {
  font-weight: 500;
}

.series_content div {
  box-sizing: border-box;
}

.series_content .series-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15625rem;
}

.series_content .seriesCategory_title {
  height: 0.223958rem;
  font-size: 0.1875rem;
  font-family: 'GothamGRBook';
  font-weight: normal;
  line-height: 0.223958rem;
  font-weight: 500;
  color: var(--theme-color);
}

.series_content .series_details {
  padding: 0 0.104167rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--viewAll-bg);
  border-radius: 0.0625rem;
  font-size: 0.145833rem;
  height: 0.270833rem;
  margin-left: 0.0625rem;
  color: var(--theme-color);
}

.series_content .details.focused {
  background: var(--theme-btn-highlight);
}

.series_content .tag_list {
  display: flex;
  align-items: center;
  margin: 0.208333rem 0;
}

.series_content .type-tag {
  width: 0.833333rem;
  padding: 0.03125rem 0;
  border-radius: 0.020833rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.125rem;
  background: var(--type-tag-bg);
  border: 0.005208rem solid var(--type-tag-border);
  color: var(--type-tag-color);
}

.series_content .type-tag span {
  color: var(--type-tag-color) !important;
}

.series_content .series-content {
  margin-top: 15vh;
}