.updatePin_Dialog {
  width: 4.447917rem;
  border-radius: 0.03125rem;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--theme-dialog-backgound);
  z-index: 9999999;
  padding-bottom: 0.369792rem;
  display: none;
}

.updatePin_content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.34375rem;
}

.updatePin_content_img {
  width: 0.822917rem;
  height: 0.677083rem;
  display: block;
  margin-bottom: 0.135417rem;
}

.updatePinSuccess {
  height: 0.239583rem;
  line-height: 0.239583rem;
  font-size: 0.197917rem;
  font-family: "GothamGRBook";
  font-weight: 400;
  text-align: center;
  color: var(--dialog-content-color);
}

.updatePin_img {
  width: 0.739583rem;
  height: 0.739583rem;
  display: block;
}

.updatePin_num {
  height: 0.302083rem;
  line-height: 0.302083rem;
  font-size: 0.25rem;
  font-family: "GothamGRBook";
  font-weight: 400;
  text-align: center;
  color: var(--dialog-content-color);
  margin: 0.145833rem 0 0.270833rem;
}

.updatePinSuccess_hint {
  height: 0.151042rem;
  font-size: 0.125rem;
  font-family: "GothamGRBook";
  font-weight: 400;
  text-align: center;
  color: #707070;
}