.ad-player {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--theme-bg);
  top: 0;
  left: 0;
  z-index: 12;
}

.ad-player .downtime-box {
  position: absolute;
  left: 2%;
  top: 2%;
  padding: 0.083333rem;
  color: var(--theme-color);
  background: var(--transparent-background-color);
  z-index: 13;
  font-size: 0.166667rem;
  border-radius: 0.020833rem;
}

.ad-player .ad-skip_btn {
  position: absolute;
  bottom: 2%;
  right: 2%;
  min-width: 1.177083rem;
  padding: 0 0.0625rem;
  height: 0.416667rem;
  border-radius: 0.052083rem;
  background: var(--theme-btn-bg);
  align-items: center;
  justify-content: center;
  z-index: 13;
}

.ad-player .ad-skip_btn .skip-icon {
  width: 0.083333rem;
  height: 0.098958rem;
}

.ad-player .ad-skip_btn .skip-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ad-player .ad-skip_btn .skip-text {
  font-size: 0.177083rem;
  margin-left: 0.0625rem;
}