.alert {
  width: 50vw;
  padding: 0.083333rem;
  text-align: center;
  position: fixed;
  top: 2vh;
  left: 0;
  right: 0;
  font-size: 0.104167rem;
  border-radius: 0.015625rem;
  margin: auto;
  display: none;
  z-index: 99;
}

.is-success {
  color: #67c23a;
  background: #f0f9eb;
}

.is-warning {
  color: #e6a23c;
  background: #fdf6ec;
}

.is-error {
  color: #f56c6c;
  background: #fef0f0;
}