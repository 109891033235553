body {
  margin: 0;
  font-family: 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  margin: 0;
  background: #000;
  color: #fff;
  /* background-size: cover; */
}

body::-webkit-scrollbar {
  width: 0 !important;
}

body {
  -ms-overflow-style: none;
}

body {
  overflow: -moz-scrollbars-none;
}

img {
  width: 100%;
  height: 100%;
}

input {
  font-family: 'helvetica neue', tahoma, 'PingFang SC', 'microsoft yahei', arial, 'hiragino sans gb', sans-serif;
}

@-webkit-keyframes rotateAnim {
  /* 旋转动画 */
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes rotateAnim {
  /* 旋转动画 */
  0% {
    -webkit-transform: rotate(0deg);
  }

  50% {
    -webkit-transform: rotate(180deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.levelShake {
  -webkit-animation: levelShake 800ms ease-in-out;
  animation: levelShake 800ms ease-in-out;
}

.verticalShake {
  -webkit-animation: verticalShake 800ms ease-in-out;
  animation: verticalShake 800ms ease-in-out;
}

@-webkit-keyframes levelShake {
  /* 水平抖动，核心代码 */
  10%, 90% {
    -webkit-transform: translate3d(-0.005208rem, 0, 0);
    transform: translate3d(-0.005208rem, 0, 0);
  }

  20%, 80% {
    -webkit-transform: translate3d(+0.020833rem, 0, 0);
    transform: translate3d(+0.020833rem, 0, 0);
  }

  30%, 70% {
    -webkit-transform: translate3d(-0.052083rem, 0, 0);
    transform: translate3d(-0.052083rem, 0, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(+0.052083rem, 0, 0);
    transform: translate3d(+0.052083rem, 0, 0);
  }

  50% {
    -webkit-transform: translate3d(-0.052083rem, 0, 0);
    transform: translate3d(-0.052083rem, 0, 0);
  }
}

@keyframes levelShake {
  /* 水平抖动，核心代码 */
  10%, 90% {
    -webkit-transform: translate3d(-0.005208rem, 0, 0);
    transform: translate3d(-0.005208rem, 0, 0);
  }

  20%, 80% {
    -webkit-transform: translate3d(+0.020833rem, 0, 0);
    transform: translate3d(+0.020833rem, 0, 0);
  }

  30%, 70% {
    -webkit-transform: translate3d(-0.052083rem, 0, 0);
    transform: translate3d(-0.052083rem, 0, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(+0.052083rem, 0, 0);
    transform: translate3d(+0.052083rem, 0, 0);
  }

  50% {
    -webkit-transform: translate3d(-0.052083rem, 0, 0);
    transform: translate3d(-0.052083rem, 0, 0);
  }
}

@-webkit-keyframes verticalShake {
  /* 垂直抖动，核心代码 */
  10%, 90% {
    -webkit-transform: translate3d(0, -0.005208rem, 0);
    transform: translate3d(0, -0.005208rem, 0);
  }

  20%, 80% {
    -webkit-transform: translate3d(0, +0.010417rem, 0);
    transform: translate3d(0, +0.010417rem, 0);
  }

  30%, 70% {
    -webkit-transform: translate3d(0, -0.020833rem, 0);
    transform: translate3d(0, -0.020833rem, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(0, +0.020833rem, 0);
    transform: translate3d(0, +0.020833rem, 0);
  }

  50% {
    -webkit-transform: translateY(-0.020833rem);
    transform: translateY(-0.020833rem);
  }
}

@keyframes verticalShake {
  /* 垂直抖动，核心代码 */
  10%, 90% {
    -webkit-transform: translate3d(0, -0.005208rem, 0);
    transform: translate3d(0, -0.005208rem, 0);
  }

  20%, 80% {
    -webkit-transform: translate3d(0, +0.010417rem, 0);
    transform: translate3d(0, +0.010417rem, 0);
  }

  30%, 70% {
    -webkit-transform: translate3d(0, -0.020833rem, 0);
    transform: translate3d(0, -0.020833rem, 0);
  }

  40%, 60% {
    -webkit-transform: translate3d(0, +0.020833rem, 0);
    transform: translate3d(0, +0.020833rem, 0);
  }

  50% {
    -webkit-transform: translateY(-0.020833rem);
    transform: translateY(-0.020833rem);
  }
}

[focused] {
  background-color: var(--theme-btn-highlight) !important;
}

.recommend-item[focused] {
  background-color: inherit !important;
}

.photoFull[focused] {
  background-color: inherit !important;
}

.package-item[focused] {
  background-color: inherit !important;
}

/* loading 图标样式 */

.loading-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0.208333rem;
  height: 0.208333rem;
  margin-top: -0.104167rem;
  /* 兼容不支持 transform 的老旧浏览器 */
  margin-left: -0.104167rem;
  /* 兼容不支持 transform 的老旧浏览器 */
  border: 0.020833rem solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: var(--theme-color);
  -webkit-animation: spin 1s ease-in-out infinite;
  /* 兼容 Safari */
  animation: spin 1s ease-in-out infinite;
  /* display: none; */
}

/* 旋转动画 */

@-webkit-keyframes spin {
  /* 兼容 Safari */
  to {
    -webkit-transform: rotate(360deg);
  }

  /* 兼容 Safari */}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }

  /* background: var(--theme-btn-highlight) !important; */}

.content {
  width: 100%;
  min-height: 4.166667rem;
  box-sizing: border-box;
  padding: 0.177083rem 0 0;
  position: relative;
}

.content::-webkit-scrollbar {
  display: none;
}

.detail-list_content {
  min-height: 0.416667rem;
}

.category_title {
  height: 0.21875rem;
  font-size: 0.1875rem;
  font-family: 'GothamGRBook';
  font-weight: normal;
  line-height: 0.1875rem;
  color: #FFFFFF;
  margin-bottom: 0.104167rem;
  margin-top: 0.104167rem;
  padding-left: 0.1875rem;
}

.watermark {
  width: 0.9375rem;
  /* height: 180px; */
  position: absolute;
  z-index: 11;
}

.watermark img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.age-grade {
  width: 0.260417rem;
  /* height: 50px; */
  position: absolute;
  bottom: 0.260417rem;
  right: 0.260417rem;
}

.age-grade img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.player-tips {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: var(--theme-bg);
  top: 0;
  left: 0;
  z-index: 13;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.1875rem;
}

.ad-contenter {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
  /* display: none; */
}

.ad-images_container {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  top: 0;
  left: 0;
  display: none;
  /* transform: rotate(45deg); */
  /* transition: transform 0.6s ease-in-out; */
}

.ad-images_container.horizontal {
  justify-content: center;
}

.ad-images_container.vertical {
  align-items: center;
}

.ad-images_container .ad-item {
  position: absolute;
  pointer-events: auto;
  z-index: 100;
  zoom: 0.8;
}

.ad-images_container .ad-item .ad-main img {
  display: block;
  width: 100%;
  height: 100%;
}

.ad-images_container .ad-close {
  width: 0.208333rem;
  height: 0.208333rem;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  right: 0;
  top: 0;
}

.ad-images_container .ad-close img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ad-images_container .ad-item.horizontal.has-qr_code {
  width: 8.333333rem;
}

.ad-images_container .ad-item.horizontal .ad-main {
  width: 7.291667rem;
  height: 1.041667rem;
}

.ad-images_container .ad-item.horizontal {
  height: 1.041667rem;
  /* left: 2.6rem; */
}

.ad-images_container .ad-item.vertical {
  width: 1.041667rem;
}

.ad-images_container .ad-item.center .ad-main {
  width: 6.25rem;
  height: 4.166667rem;
}

.ad-images_container .ad-item.vertical.has-qr_code {
  height: 4.166667rem;
}

.ad-images_container .ad-item.vertical .ad-main {
  height: 3.125rem;
}

.ad-images_container .ad-item.ad-top.horizontal {
  top: 0.572917rem;
}

.ad-images_container .ad-item.ad-top {
  left: 50%;
  -webkit-transform: translatex(-50%);
  transform: translatex(-50%);
}

.ad-images_container .ad-item.ad-bottom.horizontal {
  bottom: 0.572917rem;
}

.ad-images_container .ad-item.ad-bottom {
  left: 50%;
  -webkit-transform: translatex(-50%);
  transform: translatex(-50%);
}

.ad-images_container .ad-item.ad-left {
  left: 0.625rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ad-images_container .ad-item.ad-right {
  right: 0.625rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ad-images_container .ad-item.center {
  top: 50%;
  /* transform: translateY(-50%); */
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotateY(0deg);
  transform: translate(-50%, -50%) rotateY(0deg);
  /* 初始位置 */
  /* display: flex;
	align-items: center;
	justify-content: center; */
}

.ad-images_container .ad-item.horizontal .qr-code {
  width: 1.041667rem;
  height: 1.041667rem;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.104167rem;
  background: #fff;
  box-sizing: border-box;
}

.ad-images_container .ad-item .qr-code .qr-code_img {
  width: 0.833333rem !important;
  height: 0.833333rem !important;
}

.ad-images_container .ad-item.vertical .qr-code {
  width: 1.041667rem;
  height: 1.041667rem;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.104167rem;
  background: #fff;
  box-sizing: border-box;
}

.rich-text {
  padding: 2% 3%;
  background-color: var(--theme-bg);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

.rich-text iframe {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.rich-text_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.rich-text p {
  background-color: var(--theme-bg);
}

.rich-text a,
span {
  color: var(--theme-color);
}

.rich-text .more-box {
  position: fixed;
  bottom: 0.104167rem;
  margin: 0 50%;
  left: 0;
  right: 0;
  width: 1.041667rem;
  /* height: 200px; */
  border-radius: 50%;
  line-height: 0.3125rem;
  text-align: center;
  display: flex;
}

.rich-text .more-button {
  width: 0.416667rem;
  height: 0.416667rem;
  border-radius: 50%;
  background: var(--transparent-background-color);
  /* line-height: 60px; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rich-text .more-button:first-child {
  margin-right: 0.083333rem;
}

.rich-text .more-button span {
  width: 0.260417rem;
  height: 0.260417rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.auto-scroll {
  display: inline-block;
  -webkit-animation: scroll 10s linear infinite;
  animation: scroll 10s linear infinite;
}

.modal-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 4.166667rem;
  height: 2.604167rem;
  background: var(--theme-border-color);
  border-radius: 0.041667rem;
  z-index: 96;
}

.modal-layer .icon {
  height: 0.520833rem;
  border-bottom: 0.010417rem solid var(--type-tag-border);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-layer .icon img {
  height: 0.364583rem;
  width: auto;
}

.modal-layer h2 {
  color: var(--theme-highlight-color);
  font-size: 0.145833rem;
  text-align: center;
  padding-top: 0.1875rem;
}

.modal-layer p {
  font-size: 0.135417rem;
  color: var(--theme-color);
  padding-top: 0.125rem;
  text-align: center;
}

.modal-layer .btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 0.458333rem;
  text-align: center;
  line-height: 0.416667rem;
  border-radius: 0.03125rem;
  background-color: var(--dialog-btn-Item-bg);
  width: 0.833333rem;
  margin: auto;
  margin-top: 0.333333rem;
  font-size: 0.135417rem;
}

.modal-layer .btn[focused] {
  background: var(--theme-btn-highlight);
}