.payment-page {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 0.25rem 0.5rem;
}

.payment-page .page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-page .page-title .title {
  font-size: 0.21875rem;
}

.payment-page .page-title .page-title_right {
  display: flex;
  align-items: center;
}

.payment-page .page-title .page-title_right .icon {
  margin-right: 0.0625rem;
}

.payment-page .page-title .page-title_right .secure {
  font-size: 0.166667rem;
  margin-bottom: 0.041667rem;
}

.payment-page .page-title .page-title_right .payment-text {
  font-size: 0.145833rem;
  color: var(--tag-bg);
}

.payment-page .pay-gatways {
  position: relative;
  width: 1.770833rem;
  height: 0.666667rem;
}

.payment-page .pay-gatways .pay-gatways_wrapper {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}

.payment-page .pay-gatways .pay-gatways_wrapper .pay-gatway_item {
  width: 1.770833rem;
  height: 0.666667rem;
  border-radius: 0.083333rem;
  border: 0.010417rem solid;
  background: var(--theme-color);
  margin-right: 0.125rem;
  position: relative;
}

.payment-page .pay-gatways .pay-gatways_wrapper .pay-gatway_item img {
  width: auto;
  width: initial;
  height: auto;
  height: initial;
  max-width: 100%;
  border-radius: 0.083333rem;
  object-fit: cover;
}

.payment-page .pay-gatways .pay-gatways_wrapper .pay-gatway_item .checked {
  position: absolute;
  right: 0.052083rem;
  top: 0.052083rem;
  width: 0.1875rem;
  height: 0.1875rem;
}

.payment-page .pay-gatways .pay-gatways_wrapper .pay-gatway_item .checked img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.payment-page .pay-gatways .pay-gatways_wrapper .pay-gatway_item[focused] {
  background-color: var(--theme-color) !important;
  border-color: var(--leaderboard-first-color);
}

.payment-page .tips {
  position: absolute;
  bottom: 0.166667rem;
  right: 0.5rem;
  font-size: 0.145833rem;
}

.payment-page .order-info {
  font-size: 0.145833rem;
  margin-top: 0.416667rem;
  display: flex;
  justify-content: space-between;
}

.payment-page .order-info .order-info_title {
  font-size: 0.25rem;
  margin-bottom: 0.125rem;
}

.payment-page .order-info .order-info_item {
  margin-bottom: 0.083333rem;
  display: flex;
}

.payment-page .order-info .order-info_item .label {
  color: var(--theme-dialog-color);
  margin-right: 0.083333rem;
}

.payment-page .order-info .order-info_item .waiting {
  color: var(--theme-btn-highlight);
}

.payment-page .qr-box {
  width: 2.109375rem;
  height: 2.151042rem;
  background-image: url("../../../assets/images/icon/pre_qr_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.payment-page .qr-box .qr-wrapper {
  width: 1.901042rem;
  height: 1.942708rem;
  padding: 0.052083rem;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  background: var(--theme-color);
}

.payment-page .qr-box .qr-wrapper .qr-code_img {
  width: 1.692708rem !important;
  height: 1.692708rem !important;
}

.payment-page .downtime {
  font-size: 0.125rem;
  display: flex;
  justify-content: center;
}

.payment-page .downtime .icon {
  width: 0.15625rem;
  height: 0.15625rem;
  margin-right: 0.0625rem;
}

.payment-page .downtime .icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}