:root {
  /* --theme-bg: #12141a; */
  --theme-bg: #000;
  --theme-color: #fff;
  --theme-btn-highlight: #0472b3;
  /* --theme-btn-bg: #CEE800; */
  --theme-btn-bg: #0472b3;
  --theme-btn-color: #fff;
  --theme-btn-category-bgColor: rgba(255, 255, 255, 0.2);
  --theme-float-box-color: #141414;
  --theme-float-box-shadow: inset 0 -0.005208rem 0 rgba(255, 255, 255, 0.25);
  --theme-border-color: #282A2C;
  --theme-btn-highlight-color: #0472b3;
  --theme-pc-font-size: .16rem;
  --theme-highlight-color: #0472b3;
  --theme-menu-active-color: #0472b3;
  --theme-menu-color: #fff;
  --theme-dialog-backgound: #14212D;
  --theme-dialog-color: #777777;
  --theme-dialog-title-color: #333333;
  --theme-dialog-btn-color: #ffffff;
  --theme-dialog-btn-cancel-color: #333333;
  --theme-video-background: #191919;
  --theme-dialog-title-border: 0.16rem solid #F7F7F7;
  --theme-input-text-color: #606266;
  --input-border-base: 0.01rem solid #dcdfe6;
  --input-border-color: #dcdfe6;
  --theme-input-tips-color: #F56C6C;
  --placeholder-text-color: #c1c1c1;
  --theme-buy-btn-bg: #0472b3;
  --theme-buy-btn-color: #ffffff;
  --theme-detail-btn-bg: #0472b3;
  --theme-detail-btn-color: #333333;
  --theme-redeemCode-input: #000;
  --leaderboard-first-color: #FF4444;
  --leaderboard-second-color: #FF5B1E;
  --leaderboard-third-color: #FF9C1B;
  --border-color: #222222;
  --shortVideo-home-videoName-color: #ffffff;
  --shortVideo-home-hitCount-color: rgba(255, 255, 255, 0.50);
  --comment-input-color: rgba(255, 255, 255, 0.1);
  --icon-highlight-color: #FE3225;
  --transparent-background-color: rgba(0, 0, 0, 0.50);
  --transparent-background-color90: rgba(0, 0, 0, 0.9);
  --theme-shadow: #2C2C2C;
  --tag-bg: rgba(255, 255, 255, 0.8);
  --float-header-bg: #222222;
  --color-9A9A9A: #9A9A9A;
  --theme-content-bg: #222222;
  --categoryItem-focus-bg: #0472b3;
  --categoryItem-color: rgba(255, 255, 255, 0.8);
  --del-bg: #4d4f50;
  --details-btn-bg: #4C4C4E;
  --title-color: #eeeeee;
  --intro-color: #D1D1D1;
  --empty-highlight-color: red;
  --searchInput-input-bg: rgba(40, 42, 44, 0.44);
  --searchInput-input-color: #d7d7d7;
  --searchInput-placeholder-color: #6d6b6b;
  --type-tag-color: #333;
  --viewAll-bg: #f3eeee26;
  --type-tag-bg: rgba(255, 255, 255, 0.8);
  --type-tag-border: rgba(255, 255, 255, 0.8);
  --vodFrist-item-color: #D7D7D7;
  --dialog-content-color: #D7D7D7;
  --dialog-btn-Item-bg: #515A61;
  --dialog-input-placeholder: #D1D1D1;
  --dialog-pin-bg: #2C3842;
  --border-color: #fff;
}