.header {
  height: 0.416667rem;
  width: calc(100% - 1rem);
  top: 0;
  padding: 0 0.5rem;
}

.header .nav-header {
  height: 100%;
  width: 100%;
  justify-content: space-between;
}

.header .nav-header .header-left,
.header .nav-header .header-right {
  display: flex;
  align-items: center;
}

.header .nav-header .header-left {
  flex: 1 1;
}

.header .nav-header .search-icon {
  width: 0.208333rem;
  height: 0.208333rem;
  padding: 0.0625rem;
  border-radius: 50%;
  margin-right: 0.052083rem;
}

.header .nav-header .search-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header .nav-header .notification-number {
  border-radius: 50%;
  width: 0.333333rem;
  height: 0.333333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .nav-header .notification-number .notification-number_wrapper {
  width: 0.208333rem;
  height: 0.208333rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1PX solid var(--border-color);
  font-size: 0.125rem;
  color: var(--theme-highlight-color);
}

.header .nav-header .notification-number[focused] .notification-number_wrapper {
  color: var(--theme-btn-color);
}

.header .nav-header .setting-icon {
  width: 0.208333rem;
  height: 0.208333rem;
  padding: 0.0625rem;
  border-radius: 50%;
  margin-left: 0.125rem;
}

.header .nav-header .setting-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header .nav-header .menu-list {
  display: flex;
  flex: 1 1;
  overflow: hidden;
  position: relative;
  height: 100%;
  align-items: center;
}

.header .nav-header .menu-list .menu-list_wrapper {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.header .nav-header .menu-list .menu-item {
  padding: 0.041667rem 0.166667rem;
  margin-left: 0.0625rem;
  font-size: 0.166667rem;
  border-radius: 0.182292rem;
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
}

.header .logo-header {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .logo-header .logo {
  width: 0.833333rem;
  height: 0.239583rem;
}

.header .logo-header .logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header .logo-header .arrow-up {
  width: 0.166667rem;
  height: 0.083333rem;
}

.header .logo-header .arrow-up img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header .logo-header .menu-name_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header .logo-header .menu-name {
  font-size: 0.125rem;
}

.header .logo-header .time {
  font-size: 0.166667rem;
}