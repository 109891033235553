.sign_out {
  width: 4.239583rem;
  font-size: 0.177083rem;
  border-radius: 0.03125rem;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--theme-dialog-backgound);
  z-index: 9999999;
  padding-bottom: 0.572917rem;
  display: block;
}

.sign_out_logo {
  width: 100%;
  height: 0.71875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.010417rem solid rgba(255,255,255,0.5);
}

.sign_out_logo img {
  width: 1.067708rem;
  height: 0.364583rem;
  display: block;
}

.sign_out_title {
  line-height: 0.3125rem;
  width: 100%;
  padding: 0.260417rem 0.458333rem 0.338542rem;
  text-align: center;
}

.sign_out_title div {
  line-height: 0.3125rem;
  text-align: center;
}

.sign_out_btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 0.458333rem;
}

.sign_out_btn_Item {
  width: 1.395833rem;
  height: 0.416667rem;
  text-align: center;
  line-height: 0.416667rem;
  border-radius: 0.03125rem;
  background-color: var(--dialog-btn-Item-bg);
}

.sign_out_btn_Item.focused {
  background: var(--theme-btn-highlight);
}